import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { chatSelector, usersSelector } from 'src/selectors'
import MessageInner from './MessageInner/MessageInner'
import SvgIcon from 'src/components/common/SvgIcon'
import LeftChevron from 'src/assets/icons/left_chevron.svg'
import debounce from 'lodash/debounce'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import { fullName } from 'src/services/user_helpers'
import ThreadDrawer from './ThreadDrawer/ThreadDrawer'

import styles from './view-message.scss'

const ViewMessage = () => {
  const users = useSelector(state => usersSelector(state))
  const { messageList, chatMessages, chatUser, replyMessage } = useSelector(chatSelector)

  const messagesRef = useRef(null)
  const messagesCountRef = useRef(0)
  const replyMessageRef = useRef(null)
  const replyMessageCountRef = useRef(0)

  useEffect(() => {
    const message = chatMessages[replyMessage?.id]
    if (
      replyMessageRef.current &&
      message &&
      message.replies.length > replyMessageCountRef.current
    ) {
      replyMessageRef.current.scrollTop = replyMessageRef.current.scrollHeight

      replyMessageCountRef.current = message.replies.length
    }
  })

  useEffect(() => {
    if (messagesRef.current && messageList.length > messagesCountRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight
    }

    messagesCountRef.current = messageList.length
  }, [messageList.length])

  const handleScroll = debounce(() => {
    console.log('scrolling')
  }, 300)

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (messagesRef.current) {
        messagesRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  const renderTitle = () => {
    if (chatUser && chatUser !== 'allUsers') {
      return (
        <>
          <UserAvatarContainer user={chatUser} size={34} />
          {fullName(chatUser)}
        </>
      )
    }
    return '# Group Chat'
  }

  return (
    <div className={styles['view-message-container']}>
      <div className={styles['header']}>
        <SvgIcon
          icon={LeftChevron}
          className={styles['left-icon']}
          onClick={() => history.back()}
        />
        <div className={styles['title']}>{renderTitle()}</div>
      </div>

      <div className={styles['message-content']} ref={messagesRef}>
        {messageList.map(message => {
          const user = users[message.user_id]
          return <MessageInner key={message.id} message={message} user={user} />
        })}
      </div>

      <ThreadDrawer />
    </div>
  )
}

export default ViewMessage
