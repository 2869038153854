import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  eventSelector,
  hasPaidTicketsSelector,
  initialLoadingPaymentModalSelector,
  invitationIdSelector,
  purchaseTicketsLoadingSelector,
  rsvpModalHasChangedRsvpStatusSelector,
  showGuestsChangeRsvpModalSelector,
  showInvitationReplyFormModalSelector,
  tempTicketTypesCountsSelector,
  invitationsAcceptedCountSelector
} from '../../selectors'
import styles from './free-tickets-container.scss'
import TotalTicketsCounts from './TotalTicketsCounts'
import Button from '../common/Button'
import TicketCountsSelector from './TicketCountsSelector'

import {
  setShowGuestsChangeRsvpModal,
  showInvitationReplyFormModal,
  purchaseTickets,
  setRequestedRsvp
} from '../../actions/paymentGraphql'
import { requestAcceptInvitation } from 'src/actions/invitations'
import { changeTicketCounts, deepCopyArray, pluralize, sumCount } from '../../services/utils'
import { showHud } from 'src/actions/frontend'
import { hasUserAccessToken } from 'services/access_tokens'
import { showLogin } from 'src/actions/login'

import OverlayLoadingIndicator from 'components/common/OverlayLoadingIndicator'
import InvitationReplyFormContainer from './InvitationReplyFormContainer'
import Alert from '../details/Alert'

class FreeTicketsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ticketCounts: [],
      isError: false
    }
  }

  componentWillMount() {
    const { ticketTypesCounts } = this.props
    this.setState({
      ticketCounts: deepCopyArray(ticketTypesCounts)
    })
  }

  componentDidMount() {
    const { isPaidEvent, ticketTypesCounts } = this.props

    const totalTicketCount = sumCount(ticketTypesCounts)

    // Select one ticket by default if the user doesn't have any tickets yet
    if (!isPaidEvent && totalTicketCount === 0) {
      const ticketType = ticketTypesCounts.filter(
        ticketType => ticketType.label === 'adult' && ticketType.archived_at === null
      )[0]
      if (ticketType) {
        this._onChangeCount(ticketType.id, 1)
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const tempTicketTypesCounts = nextProps.tempTicketTypesCounts.length
      ? deepCopyArray(nextProps.tempTicketTypesCounts)
      : deepCopyArray(nextProps.ticketTypesCounts)
    this.setState({
      ticketCounts: tempTicketTypesCounts
    })
  }

  _renderInvitationReplyForm() {
    const {
      invitationReplyForm,
      invitation,
      guestCountEnabled,
      changeRsvp,
      hasChangedRsvpStatus,
      showInvitationReplyFormModal
    } = this.props

    return (
      <InvitationReplyFormContainer
        changeRsvp={changeRsvp}
        invitation={invitation}
        guestCountEnabled={guestCountEnabled}
        invitationReplyForm={invitationReplyForm}
        onClickAttending={this._changeRsvp}
        hasChangedRsvpStatus={hasChangedRsvpStatus}
        showInvitationReplyFormModal={showInvitationReplyFormModal}
      />
    )
  }

  _changeRsvp = () => {
    const { guestCountEnabled, onDeclineInvitation, onShowGuestsChangeRsvpModal } = this.props
    if (guestCountEnabled) {
      onShowGuestsChangeRsvpModal()
    } else {
      onDeclineInvitation()
    }
  }

  _onChangeCount = (ticketTypeId, count) => {
    let { ticketCounts } = this.state
    const { onSetTempTicketTypesCounts } = this.props

    ticketCounts = changeTicketCounts(ticketCounts, ticketTypeId, count)

    this.setState({
      ticketCounts,
      isError: false
    })

    onSetTempTicketTypesCounts(ticketCounts)
  }

  _setOrderLineItems = () => {
    const { onSetOrderLineItems, ticketTypesCounts, guestCountEnabled, listMyOrdersForEvent } =
      this.props
    const listMyOrderFlatTickets = listMyOrdersForEvent.flatMap(item => item.tickets)
    const { ticketCounts } = this.state
    const queryVariables = []
    let revokeFreeTicketsIds = []
    ticketTypesCounts.forEach(item => {
      const new_count = ticketCounts.find(value => value.id === item.id).count

      if (!guestCountEnabled) {
        if (!new_count) {
          const obj = { ticket_type_id: item.id, count: 1 }
          queryVariables.push(obj)
        }
      } else if (new_count - item.count > 0) {
        const obj = { ticket_type_id: item.id, count: new_count - item.count }
        queryVariables.push(obj)
      } else if (new_count - item.count < 0) {
        const filterTicketsByTicketTypeId = listMyOrderFlatTickets.filter(
          orders => orders.ticket_type_id === item.id
        )
        const activeTickets = filterTicketsByTicketTypeId.filter(
          orders => orders.state === 'active'
        )
        revokeFreeTicketsIds = revokeFreeTicketsIds.concat(
          activeTickets.slice(0, item.count - new_count).map(orders => orders.id)
        )
      }
    })
    onSetOrderLineItems(queryVariables)
    return { orderLineItems: queryVariables, revokeFreeTicketsIds }
  }

  _saveChange = () => {
    const {
      onRevokeFreeTickets,
      invitationId,
      isInvitationCredentialUser,
      onPurchaseTickets,
      ticketTypesCounts,
      onSetRequestedRsvp
    } = this.props

    const { orderLineItems = [], revokeFreeTicketsIds = [] } = this._setOrderLineItems()

    let revokeFreeRequest = revokeFreeTicketsIds.length > 0
    let purchaseRequest = orderLineItems.length > 0

    if (revokeFreeRequest) {
      let acceptAction = null
      if (sumCount(ticketTypesCounts) === revokeFreeTicketsIds.length) {
        onSetRequestedRsvp('accepted')
        acceptAction = [requestAcceptInvitation()]
      }

      const successActions = purchaseRequest
        ? [
            purchaseTickets(invitationId, orderLineItems, null, !isInvitationCredentialUser, {
              successActions: acceptAction
            })
          ]
        : [showInvitationReplyFormModal()]

      onRevokeFreeTickets(revokeFreeTicketsIds, successActions)

      return
    }

    if (purchaseRequest) {
      onPurchaseTickets(invitationId, orderLineItems, null, !isInvitationCredentialUser)
    }
  }

  verifyInvitationGuest = () => {
    const {
      event: { max_capacity, limit_per_invitation },
      invitationsAcceptedCount,
      ticketTypesCounts,
      onShowHud
    } = this.props
    const { ticketCounts } = this.state

    const initialCount = ticketTypesCounts.reduce((acc, ticket) => ticket.count + acc, 0)
    const otherGuest = invitationsAcceptedCount - initialCount

    const totalTickets = ticketCounts.reduce((acc, ticket) => ticket.count + acc, 0)

    if (limit_per_invitation !== 9999 && totalTickets > limit_per_invitation) {
      onShowHud(`Maximum of ${limit_per_invitation} guest(s) event`)
      return false
    }

    if (max_capacity !== 9999 && totalTickets + otherGuest > max_capacity) {
      this.setState({ isError: true })
      return false
    }

    return true
  }

  calculateRemainingCount = () => {
    const {
      event: { max_capacity },
      invitationsAcceptedCount,
      ticketTypesCounts
    } = this.props
    const { ticketCounts, isError } = this.state

    const initialCount = ticketTypesCounts.reduce((acc, ticket) => ticket.count + acc, 0)
    const otherGuest = invitationsAcceptedCount - initialCount
    const totalTickets = ticketCounts.reduce((acc, ticket) => ticket.count + acc, 0)
    const remainingCount =
      max_capacity - (isError ? invitationsAcceptedCount : totalTickets + otherGuest)
    return remainingCount
  }

  _handleSave = () => {
    if (!this.verifyInvitationGuest()) {
      return
    }
    const { changeRsvp, onShowLogin } = this.props
    if (!hasUserAccessToken()) {
      onShowLogin({
        loginInRSVP: true,
        loginModalTitle: changeRsvp
          ? 'Verify your Name & Number to confirm your RSVP changes'
          : 'Confirm your Name & Number for your Host'
      })
    }

    this._saveChange()
  }

  renderRemainingCountText = () => {
    const { isError, ticketCounts } = this.state
    const totalTickets = ticketCounts.reduce((acc, ticket) => ticket.count + acc, 0)
    const remainingCount = this.calculateRemainingCount()
    let message = ''

    if (isError) {
      return (
        <div className={styles['max-capacity-alert']}>
          <Alert
            type="alert-no-border"
            message={'Sorry, there are only ' + remainingCount + ' spots remaining! '}
          />
        </div>
      )
    }

    if (remainingCount < 0) {
      message = "Your RSVP exceeds the event's max capacity"
    } else {
      message = 'There are currently ' + remainingCount + ' spots left.'
    }

    return (
      remainingCount < totalTickets && (
        <div className={styles['max-capacity-warning']}>
          <Alert type="warning-no-icon" message={message} />
        </div>
      )
    )
  }

  _renderGuestsSelectorModal = () => {
    const {
      isPaidEvent,
      ticketTypesCounts,
      showTicketCounts,
      purchaseTicketsLoading,
      onDeclineInvitation,
      changeRsvp,
      hasChangedRsvpStatus,
      guestCountEnabled
    } = this.props

    const { ticketCounts } = this.state

    const numAttending = sumCount(ticketTypesCounts)

    return (
      <div className={styles['change-rsvp-wrapper']}>
        <h3 className={styles['title']}>
          {changeRsvp && !hasChangedRsvpStatus ? 'Change RSVP' : `Yes, I'm Going`}
        </h3>
        {changeRsvp && !hasChangedRsvpStatus ? (
          <div>
            <div>
              My RSVP: <strong>Yes I’m Going</strong>
            </div>
            <div className={styles['total-attending-label']}>
              Total Attending: {numAttending} {pluralize(numAttending, 'Guest')}
            </div>
            <div className={styles['tip-label']}>
              How many <strong>total guests</strong> are going?
            </div>
          </div>
        ) : (
          <div className={styles['guest-description']}>
            Including yourself, How many <strong>total guests</strong> are going?
          </div>
        )}

        {this.renderRemainingCountText()}

        <TicketCountsSelector
          guestCountEnabled={guestCountEnabled}
          isPaidEvent={isPaidEvent}
          ticketCounts={ticketCounts}
          ticketTypesCounts={ticketTypesCounts}
          showTicketCounts={showTicketCounts}
          onChangeCount={this._onChangeCount}
        />
        <Button
          disabled={!this._validConfirm()}
          onClick={() => {
            this._handleSave()
          }}
          className={styles['button']}
        >
          {changeRsvp ? 'save change' : 'confirm'}
        </Button>
        {changeRsvp && !hasChangedRsvpStatus && (
          <div className={styles['change-rsvp-label']}>
            Change RSVP:{' '}
            <a className={styles['link']} onClick={onDeclineInvitation}>
              No, Can't Go
            </a>
          </div>
        )}
        <OverlayLoadingIndicator loading={purchaseTicketsLoading} light />
      </div>
    )
  }

  _validConfirm = () => {
    const { ticketCounts } = this.state
    const { ticketTypesCounts } = this.props
    const total = sumCount(ticketCounts)
    const hasChanged = JSON.stringify(ticketCounts) !== JSON.stringify(ticketTypesCounts)
    return !!total && hasChanged
  }

  _renderComponent = () => {
    const {
      changeRsvp,
      ticketTypesCounts,
      event,
      showGuestsChangeRsvpModal,
      showInvitationReplyFormModal,
      listMyOrdersForEvent
    } = this.props

    if (showGuestsChangeRsvpModal) {
      return this._renderGuestsSelectorModal()
    }

    if (showInvitationReplyFormModal) {
      return this._renderInvitationReplyForm()
    }

    if (changeRsvp) {
      return (
        <div className={styles['wrapper']}>
          <h3 className={styles['title']}>Yes, I’m Going</h3>
          <div className={styles['event-name']}>{event.name}</div>
          <TotalTicketsCounts
            needPayTicket={false}
            ticketCounts={ticketTypesCounts}
            className={styles['total-tickets-counts']}
            listMyOrdersForEvent={listMyOrdersForEvent}
          />
          <Button onClick={this._changeRsvp} className={styles['button']}>
            {this._changeRsvpButtonText()}
          </Button>
        </div>
      )
    } else {
      return this._renderGuestsSelectorModal()
    }
  }

  _changeRsvpButtonText() {
    const { guestCountEnabled } = this.props
    if (guestCountEnabled) {
      return 'change rsvp'
    } else {
      return 'change rsvp to no'
    }
  }

  render() {
    const { initialLoadingPaymentModal } = this.props

    return (
      <div>
        {this._renderComponent()}
        <OverlayLoadingIndicator loading={initialLoadingPaymentModal} light={false} />
      </div>
    )
  }
}

FreeTicketsContainer.propTypes = {
  // Direct props
  changeRsvp: PropTypes.bool,
  guestCountEnabled: PropTypes.bool,
  invitation: PropTypes.object,
  invitationReplyForm: PropTypes.element,
  isInvitationCredentialUser: PropTypes.bool,
  listMyOrdersForEvent: PropTypes.array,
  onDeclineInvitation: PropTypes.func,
  onPurchaseTickets: PropTypes.func,
  onRevokeFreeTickets: PropTypes.func,
  onSetOrderLineItems: PropTypes.func,
  onSetTempTicketTypesCounts: PropTypes.func,
  showTicketCounts: PropTypes.bool,
  ticketTypesCounts: PropTypes.array,

  // Container props
  event: PropTypes.object,
  hasChangedRsvpStatus: PropTypes.bool,
  initialLoadingPaymentModal: PropTypes.bool,
  invitationId: PropTypes.string,
  isPaidEvent: PropTypes.bool.isRequired,
  onShowGuestsChangeRsvpModal: PropTypes.func,
  purchaseTicketsLoading: PropTypes.bool,
  showGuestsChangeRsvpModal: PropTypes.bool,
  showInvitationReplyFormModal: PropTypes.bool,
  tempTicketTypesCounts: PropTypes.array,
  invitationsAcceptedCount: PropTypes.number,
  onShowHud: PropTypes.func,
  onSetRequestedRsvp: PropTypes.func,
  onShowLogin: PropTypes.func
}

function mapStateToProps(state) {
  return {
    hasChangedRsvpStatus: rsvpModalHasChangedRsvpStatusSelector(state),
    event: eventSelector(state),
    isPaidEvent: hasPaidTicketsSelector(state),
    tempTicketTypesCounts: tempTicketTypesCountsSelector(state),
    purchaseTicketsLoading: purchaseTicketsLoadingSelector(state),
    invitationId: invitationIdSelector(state),
    showInvitationReplyFormModal: showInvitationReplyFormModalSelector(state),
    showGuestsChangeRsvpModal: showGuestsChangeRsvpModalSelector(state),
    initialLoadingPaymentModal: initialLoadingPaymentModalSelector(state),
    invitationsAcceptedCount: invitationsAcceptedCountSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onShowGuestsChangeRsvpModal: () => dispatch(setShowGuestsChangeRsvpModal()),
    onShowHud: message => dispatch(showHud('error', message)),
    onSetRequestedRsvp: requestedRsvp => dispatch(setRequestedRsvp(requestedRsvp)),
    onShowLogin: params => dispatch(showLogin(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FreeTicketsContainer)
