import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router'
import { updateMenuPanel } from 'src/actions/frontend'
import { activeMenuPanelSelector, userSelector, planSelector } from 'src/selectors'

import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import SvgIcon from 'src/components/common/SvgIcon'
import ChatIcon from 'src/assets/icons/chat.svg'
import iosIcon from 'src/assets/icons/logo.svg'
import pollListIcon from 'src/assets/icons/poll-list-icon.png'
import pollListThemeIcon from 'src/assets/icons/poll-list-icon-theme.png'
import EventIcon from 'src/assets/icons/balloon-icon-gary.png'
import EventThemeIcon from 'src/assets/icons/balloon-icon.png'
import BetaIcon from 'src/assets/icons/beta-icon.png'
import styles from './menu-bar.scss'
import PanelContainer, { PanelSlug } from './panels/PanelContainer'
import { parseEventUrl } from 'src/services/url_helpers'
import { Drawer } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { resetLoginForm } from 'src/actions/login'
import cn from 'classnames'

const MenuBar = (_, ref) => {
  const dispatch = useDispatch()
  const [isMenuSliderVisible, setIsMenuSliderVisible] = useState(false)
  const activeMenuPanel = useSelector(activeMenuPanelSelector)
  const user = useSelector(userSelector)
  const { visible: isPlanModalVisible } = useSelector(planSelector)
  const { destinationType } = parseEventUrl(window.location.pathname)
  const changeMenuPanel = panel => dispatch(updateMenuPanel(panel))

  useImperativeHandle(ref, () => ({
    onSetIsMenuSliderVisible: setIsMenuSliderVisible
  }))

  const resetMenuState = () => {
    setIsMenuSliderVisible(false)
    changeMenuPanel(null)
    dispatch(resetLoginForm())
  }

  const handleMenuItemClick = selectedPanel => {
    if (selectedPanel === activeMenuPanel && isMenuSliderVisible) {
      resetMenuState()
    } else {
      setIsMenuSliderVisible(true)
      changeMenuPanel(selectedPanel)
    }
  }

  const isPinTopMenu = () => {
    return isMenuSliderVisible || isPlanModalVisible
  }

  return (
    <div className={styles['menu-bar-container']}>
      <div className={cn(styles['menu-bar'], isPinTopMenu() && styles['menu-bar-zIndex'])}>
        <div className={styles['logo-center']}>
          <div>
            <Link to="/home">
              <SvgIcon icon={iosIcon} className={styles['logo']} />
            </Link>
          </div>
        </div>
        {/* <div className={styles['menu-bar-item']}>
          <SvgIcon icon={GroupIcon} className={styles['icon']} padding='10px' onClick={() => handleMenuItemClick('groups')} />
        </div> */}
        <Link to="/tp/timePoll">
          <div className={styles['menu-bar-item']}>
            <img
              src={destinationType === 'tp' ? pollListThemeIcon : pollListIcon}
              className={styles['icon']}
            />
          </div>
        </Link>
        <Link to="/e/list">
          <div className={styles['menu-bar-item']}>
            <img
              src={destinationType === 'e' ? EventThemeIcon : EventIcon}
              className={styles['icon']}
            />
            <img src={BetaIcon} className={styles['beta-icon']} width="25" alt="" />
          </div>
        </Link>
        <div className={styles['menu-bar-item']}>
          <SvgIcon
            icon={ChatIcon}
            className={styles['icon']}
            padding="10px"
            onClick={() => handleMenuItemClick(PanelSlug.Chat)}
          />
        </div>
        <div
          className={styles['menu-bar-item']}
          onClick={() => handleMenuItemClick(user ? PanelSlug.Profile : PanelSlug.Login)}
        >
          <UserAvatarContainer user={user || {}} size={34} inProfile />
        </div>
      </div>

      <Drawer
        open={isMenuSliderVisible}
        placement="left"
        closable={false}
        mask={false}
        getContainer={false}
        style={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
        bodyStyle={{ padding: 0 }}
        width={520}
        destroyOnClose
      >
        <div className={styles['close-panel-btn']} onClick={resetMenuState}>
          <CloseOutlined style={{ fontSize: 24 }} />
        </div>
        <PanelContainer onResetMenuState={resetMenuState} />
      </Drawer>
    </div>
  )
}

export default forwardRef(MenuBar)
