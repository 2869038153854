import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import DashboardContainer from 'containers/DashboardContainer'
import InputEventDetail from '../InputEventDetail/InputEventDetail'
import { changeEventForm } from 'src/actions/event'
import { fetchEvent } from 'src/actions'
import { eventTicketInfoRequest, setTicketTypes } from 'src/actions/paymentGraphql'
import { deepCopy } from 'services/utils'
import { setCustomFlyerURL } from 'src/actions/flyer'
import LoadingScreen from 'components/LoadingScreen'
import moment from 'moment-timezone'
import { eventSelector } from 'src/selectors'

const EditEvent = props => {
  const dispatch = useDispatch()

  const {
    params: { eventToken }
  } = props

  const [loadingEvent, setLoadingEvent] = useState(true)
  const [child_count_enabled, setChild_count_enabled] = useState(false)
  const event = useSelector(eventSelector)

  const handleSetTicketTypes = event => {
    dispatch(
      eventTicketInfoRequest({
        containerId: event.id,
        containerType: 'event',
        hostId: event.user_id,
        onSuccessCallback: res => {
          if (res?.data?.ticket_types_for_container) {
            let ticketTypes = deepCopy(res?.data.ticket_types_for_container)
            const adultTicketTypes = ticketTypes.filter(item => item.label === 'adult')
            const childTicketTypes = ticketTypes.filter(item => item.label === 'child')
            const otherTicketTypes = ticketTypes.filter(
              item => item.label !== 'adult' && item.label !== 'child'
            )
            const tempTicketTypes = adultTicketTypes
              .concat(childTicketTypes)
              .concat(otherTicketTypes)
            dispatch(setTicketTypes(tempTicketTypes))
          }
        }
      })
    )
  }

  useEffect(() => {
    let isMounted = true

    if (eventToken) {
      dispatch(
        fetchEvent(eventToken, async data => {
          if (!isMounted) return

          const { event } = data
          handleSetTicketTypes(event)
          const deepCopyEvent = deepCopy(event)
          deepCopyEvent.flyer_design_number = String(event.flyer.design_number)
          deepCopyEvent.flyer_design_slug = event.flyer.design_slug
          deepCopyEvent.starts_at = event.starts_at
            ? moment.tz(event.starts_at, event.timezone).format('YYYY-MM-DD HH:mm')
            : event.starts_at
          deepCopyEvent.ends_at = event.ends_at
            ? moment.tz(event.ends_at, event.timezone).format('YYYY-MM-DD HH:mm')
            : event.ends_at

          dispatch(changeEventForm(deepCopyEvent))
          if (event.flyer?.image_bg_upload?.image_url) {
            // 自定义flyer
            const resp = await fetch(event.flyer?.image_bg_upload?.image_url)
            const imageBlob = await resp.blob()
            const reader = new FileReader()
            reader.onload = e => {
              dispatch(setCustomFlyerURL(e.target.result))
              setChild_count_enabled(event.child_count_enabled)
              setLoadingEvent(false)
            }
            reader.readAsDataURL(imageBlob)
          } else {
            setChild_count_enabled(event.child_count_enabled)
            setLoadingEvent(false)
          }
        })
      )
    }

    return () => {
      isMounted = false
    }
  }, [])

  if (loadingEvent) {
    return <LoadingScreen event={event} />
  }

  return (
    <DashboardContainer>
      <InputEventDetail child_count_enabled={child_count_enabled} />
    </DashboardContainer>
  )
}

EditEvent.propTypes = {
  params: PropTypes.object
}

export default EditEvent
