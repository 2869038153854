import React from 'react'
import { Redirect, Route } from 'react-router'

import EventDetailContainer from 'containers/event/EventDetail/EventDetail'
import HomePageContainer from 'containers/HomePage/HomePage'
import CreateEventContainer from 'containers/event/CreateEvent/CreateEvent'
import CreateEventContainerV2 from 'containers/event/CreateEventV2/CreateEvent'
import EventListContainer from 'containers/event/EventList/EventListContainer'
import TimePollDetail from 'containers/time-poll/TimePollContainer/TimePoll/TimePollDetail/TimePollDetail'
import CreateTimePoll from 'containers/time-poll/TimePollContainer/TimePoll/CreateTimePoll/CreateTimePoll'
// import LoginPageContainer from './containers/time-poll/panels/LoginContainer'
import LoginPageContainer from 'containers/LoginPage/LoginPage'
import TimePollContainer from 'containers/time-poll/TimePollContainer/TimePollContainer'
import CreateSignUp from 'containers/time-poll/TimePollContainer/SignUp/CreateSignUp/CreateSignUp'
import SULDetail from 'containers/time-poll/TimePollContainer/SignUp/SULDetail/SULDetail'
import EditSULContainer from 'containers/time-poll/TimePollContainer/SignUp/EditSUL/EditSUL'
import EditEventContainer from 'containers/event/EditEvent/EditEvent'
import StripeRedirect from 'components/payment/stripe/Redirect'
import MembershipContainer from 'containers/MobilePage/Membership/Membership'
import PurchaseCreditContainer from 'containers/MobilePage/PurchaseCredit/PurchaseCredit'
import HolidayPromoContainer from 'containers/IOSPage/HolidayPromo'

export default (
  <Route path="/">
    <Route path="home" component={HomePageContainer} />
    <Route path="login" component={LoginPageContainer} />
    <Route path="membership" component={MembershipContainer} />
    <Route path="purchase-credit" component={PurchaseCreditContainer} />
    <Route path="holiday-promo" component={HolidayPromoContainer} />
    <Route path="e/create" component={CreateEventContainer} />
    <Route path="e/createV2" component={CreateEventContainerV2} />
    <Route path="e/list" component={EventListContainer} />
    <Route path="e/edit(/:eventToken)" component={EditEventContainer} />
    <Route path="e/:eventToken(/:invitationToken)(:/)" component={EventDetailContainer}>
      <Route path="/details" />
      <Route path="/date" />
      <Route path="/venue" />
      <Route path="/guests" />
      <Route path="/comments" />
      <Route path="/media" />
      <Route path="/orders" />
      <Route path="/orderDetail" />
      <Route path="/requestRefund" />
      <Route path="/settings" />
      <Route path="/share" />
      <Route path="/rsvp">
        <Route path="/" />
        <Route path="/accept" />
        <Route path="/decline" />
      </Route>
      <Route path="/event_links" />
      <Route path="/join_meeting" />
      <Redirect from="*" to="/e/:eventToken(/:invitationToken)(:/)" />
    </Route>
    <Route path="a/:announcementToken" component={EventDetailContainer}>
      <Redirect from="*" to="/a/:announcementToken" />
    </Route>
    <Route path="tp/create" component={CreateTimePoll} />
    <Route path="tp/timePoll" component={TimePollContainer} />
    <Route path="tp/:timePollToken(/:invitationToken)(:/)" component={TimePollDetail}>
      <Route path="/login" />
      <Route path="/successfull" />
      <Route path="/closed" />
      <Route path="/deleted" />
      {/* <Redirect from="*" to="/" /> */}
    </Route>
    <Route path="su/create" component={CreateSignUp} />
    <Route path="su/edit(/:invitationToken)" component={EditSULContainer} />
    <Route path="su/:signUpToken(/:invitationToken)(:/)" component={SULDetail} />
    <Route path="p/stripe/redirect" component={StripeRedirect} />
  </Route>
)
