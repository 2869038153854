import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { changeEventsModal } from 'src/actions/event'
import { eventSelector, eventModalSelector } from 'src/selectors'

import EventDetailPage from '../../EventDetailPage/EventDetailPage'
import EventChat from '../../EventChat/EventChat'
import GuestsListPage from '../../GuestsListPage/GuestsListPage'
import MediaPage from '../../../../MediaPage'
import OrdersPage from '../../../../OrdersPage'
import OrderDetailPage from '../../../../OrderDetailPage'
import RequestRefundPage from '../../../../RequestRefundPage'
import AddGuestsPage from '../../AddGuestsPage/AddGuestsPageContainer'
import OpenInAppModal from 'src/components/OpenInAppModal'

const EventSection = () => {
  const dispatch = useDispatch()
  const event = useSelector(eventSelector)
  const eventModal = useSelector(eventModalSelector)

  const handleChangeEventModal = tab => {
    dispatch(changeEventsModal(tab))
    window.scrollTo(0, 0)
  }

  const getCurComponent = () => {
    const components = {
      details: <EventDetailPage onChange={handleChangeEventModal} />,
      guests: <GuestsListPage />,
      media: <MediaPage />,
      chat: <EventChat />,
      orders: <OrdersPage />,
      orderDetail: <OrderDetailPage />,
      requestRefund: <RequestRefundPage />,
      addGuests: <AddGuestsPage />
    }
    let curComponent = components[eventModal]

    return curComponent || components['details']
  }

  return (
    <div style={{ height: '100%', overflow: 'hidden' }}>
      {getCurComponent()}
      <OpenInAppModal event={event} />
    </div>
  )
}

export default EventSection
