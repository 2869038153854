import React, { useEffect, useContext, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { eventSelector, chatSelector, userIdSelector } from 'src/selectors'
import { disconnectSocket, subscribeChannel, pushChannel, leaveChannel } from 'services/websocket'
import { ROOM_CHANNEL_TYPES_CONSTANTS, POPUP_BOX_TYPES_CONSTANTS } from 'src/constants'
import ViewMessage from './ViewMessage/ViewMessage'
import InputMessage from './InputMessage/InputMessage'
import {
  addMessage,
  updateMessage,
  setGroupList,
  setMemberList,
  deleteMessage,
  changeChatStatus,
  switchChatUser,
  createChatRoom,
  setChannelName,
  resetChat
} from 'src/actions/eventChat'
import PageContainerContext from 'services/contexts'
import { changeEventsModal } from 'src/actions/event'
import ChatLoading from './ChatLoading/ChatLoading'
import styles from './event-chat.scss'

const EventChat = () => {
  const dispatch = useDispatch()
  const event = useSelector(eventSelector)
  const userId = useSelector(userIdSelector)
  const { socketInstance, chatStatus, chatUser, channelName } = useSelector(chatSelector)
  const { onChangePopupBox, onChangePopupBoxCallBack } = useContext(PageContainerContext)
  const channelNameRef = useRef(null)

  useEffect(() => {
    history.pushState(null, null, location.href)
    onChangePopupBox(POPUP_BOX_TYPES_CONSTANTS.eventComments)
    onChangePopupBoxCallBack(() => dispatch(changeEventsModal('details')))
    dispatch(changeChatStatus('connecting'))

    if (socketInstance) {
      socketInstance.connect()
      dispatch(setChannelName(event.messages_channel))
      dispatch(switchChatUser('allUsers'))
    }

    return () => {
      leaveChannel(channelNameRef.current)
      dispatch(resetChat())
      disconnectSocket()
    }
  }, [])

  useEffect(() => {
    if (!chatUser || !socketInstance || chatStatus !== 'connected') return

    // It will be triggered only when chatStatus !== disconnected.
    dispatch(changeChatStatus('connecting'))
    leaveChannel(channelName)

    if (chatUser === 'allUsers') {
      dispatch(setChannelName(event.messages_channel))
    } else {
      dispatch(
        createChatRoom({
          users: [{ id: userId }, chatUser],
          onSuccessCallback: data => {
            const group = data.group

            dispatch(setChannelName(group.channel))
          }
        })
      )
    }
  }, [chatUser])

  useEffect(() => {
    if (socketInstance && channelName) {
      channelNameRef.current = channelName
      subscribeChatChannel(channelName)
      pushChatChannel()
    }
  }, [socketInstance, channelName])

  const pushChatChannel = () => {
    pushChannel({
      channelName,
      action: ROOM_CHANNEL_TYPES_CONSTANTS.room_mention_groups,
      successCallback: data => {
        const implicit_groups = data.implicit_groups.map(item => ({
          ...item,
          groupName: 'implicit_group'
        }))
        const user_groups = data.user_groups.map(item => ({ ...item, groupName: 'user_group' }))
        const groupList = implicit_groups.concat(user_groups)

        dispatch(setGroupList(groupList))
      }
    })

    // fetch members
    pushChannel({
      channelName,
      action: ROOM_CHANNEL_TYPES_CONSTANTS.room_members,
      successCallback: data => {
        dispatch(setMemberList(data.room_members))
      }
    })
  }

  const subscribeChatChannel = channelName => {
    subscribeChannel({
      channelName,
      dispatch,
      subscriberList: [
        {
          action: ROOM_CHANNEL_TYPES_CONSTANTS.listen_new_message,
          onReceived: ({ message }) => {
            console.log('message:', message)
            dispatch(addMessage(message))
          }
        },
        {
          action: ROOM_CHANNEL_TYPES_CONSTANTS.message_update,
          onReceived: ({ message }) => {
            console.log('message:', message)
            dispatch(updateMessage(message))
          }
        },
        {
          action: ROOM_CHANNEL_TYPES_CONSTANTS.message_read,
          onReceived: data => {
            console.log('data', data)
            // console.log('message:', message)
            // dispatch(updateMessage(message))
          }
        },
        {
          action: ROOM_CHANNEL_TYPES_CONSTANTS.message_destroy,
          onReceived: ({ message }) => {
            console.log('message:', message)
            dispatch(deleteMessage(message))
          }
        }
      ]
    })
  }

  return (
    <div className={styles['event-chat-container']}>
      <ViewMessage />
      <InputMessage />
      <ChatLoading />
    </div>
  )
}

export default EventChat
