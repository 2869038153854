import { call, fork, put, select, take } from 'redux-saga/effects'

import { hasUserAccessToken, setTokens } from 'services/access_tokens'
import { isHobnobAndroidClient, isHobnobIosClient, normalizrResult } from 'services/utils'
import { preloadImage } from 'services/prerenderer'
import { updateEventUrl, parseEventUrl } from 'services/url_helpers'

import { initEventCommentsCookie, initInvitationViewedCookie, initMediaCookie } from 'src/middleware'

import {
  eventNotFound,

  FETCH_EVENT_SUCCESS,
  fetchContactMethod,
  fetchEvent,
  fetchEventBranchLinks,
  fetchEventComments,
  fetchUpdates,
  getProcessedMediaCount,
  SET_USER_ID,
  setEventId,
  setEventToken,
  setDestinationType,
  setProcessedPhotoCount,
  setProcessedVideoCount
} from 'src/actions'

import { fetchCurrentUser, fetchUserGQ, FETCH_CURRENT_USER_ERROR, FETCH_CURRENT_USER_SUCCESS } from 'src/actions/user'

import { eventViewCountRequest } from 'src/actions/eventViewCount'

import {
  FETCH_ACCESS_TOKEN_ERROR,
  FETCH_ACCESS_TOKEN_SUCCESS,
  fetchAccessToken,
  REFRESH_TOKEN_ERROR
} from 'src/actions/accessTokens'

import {
  hideLoadingScreen,
  setTotalAttending,
  showMapModal,
  showOpenInAppModal
} from 'src/actions/frontend'

import {
  FETCH_INVITATION_ERROR,
  FETCH_INVITATION_SUCCESS,
  fetchInvitation,
  setInvitationId,
  setInvitationLoadingStatus,
  setInvitationToken
} from 'src/actions/invitations'
import {
  updateFlyerZoom
} from 'src/actions/flyer'

import { FETCH_MEDIA_SUCCESS, requestFetchMoreMedia } from 'src/actions/media'

import { changeEventsModal } from 'src/actions/event'

import {
  currentUsersInvitationSelector,
  eventSelector,
  invitationSelector,
  routeSelector,
  sortedMediaSelector,
  userSelector,
  destinationTypeSelector
} from 'src/selectors'

import { getOpenInAppModalClosed } from 'src/services/cookies'
import { handleRSVPRouteSaga } from 'src/sagas/rsvp_sagas'
import { AccessTokenType, accessTokenTypeSaga } from 'src/sagas/access_tokens'

import { takeAll } from './helpers'
import { paymentMethodsListRequest } from '../actions/paymentGraphql'
import { DESTINATION_TYPES_CONSTANTS } from '../constants'

export default function * bootstrapRootSaga () {
  const path = window.location.pathname
  let { destinationType, eventToken, invitationToken } = parseEventUrl(path)
  yield fork(bootstrapCookieWorker)
  yield fork(setFlyerInitialZoomSaga)
  yield fork(initializeOpenInAppModalSaga)
  yield fork(fetchCurrentUserSaga)
  yield fork(watchMediaCountSaga)
  yield put(setDestinationType(destinationType))

  const stopRoutes = ['list', 'create', 'edit', undefined, 'createV2']
  if (stopRoutes.indexOf(eventToken) > -1) {
    return
  }

  try {
    yield put(setEventToken(eventToken))
    if (invitationToken) {
      yield put(setInvitationToken(invitationToken))
      yield put(fetchEventBranchLinks(eventToken, invitationToken))
    } else {
      yield put(fetchEventBranchLinks(eventToken))
    }

    // yield fork(fetchCurrentUserSaga)
    if (destinationType === DESTINATION_TYPES_CONSTANTS.event || destinationType === DESTINATION_TYPES_CONSTANTS.announcement) {
      const invitation = yield select(invitationSelector)
      if (invitation && invitation.destroyed_at) {
        const destinationType = yield select(destinationTypeSelector)
        updateEventUrl(destinationType, eventToken)
        location.reload()
        return
      }
      yield fork(fetchEventSaga, eventToken, invitationToken)
      yield fork(fetchInvitationSaga, invitationToken, eventToken)
    }
    yield put(hideLoadingScreen())
    yield fork(loadUserSaga)
    yield fork(openModalWithUrlSaga)
  } catch (e) {
    console.error('Error loading event', e)
    yield put(eventNotFound())
  }
}

function * watchMediaCountSaga () {
  while (true) {
    const action = yield take(FETCH_EVENT_SUCCESS)
    const eventId = action.response.result.event
    const event = action.response.entities.events[eventId]
    yield put(getProcessedMediaCount(event['processed_media_count']))
    yield put(setProcessedPhotoCount(event['visible_photos_count']))
    yield put(setProcessedVideoCount(event['visible_videos_count']))
  }
}

function * openModalWithUrlSaga () {
  const url = window.location.href
  const str = url.split('#')[1]
  if (str) {
    if (str === 'venue') {
      yield put(showMapModal())
    } else {
      yield put(changeEventsModal(str))
    }
  }
}

/**
 * Has a .promise that is resolved when the intial load of the user is complete.
 * This means that we know that the user has been fetched or is not logged in
 * @type Object
 */
export const loadUserComplete = {}

loadUserComplete.promise = new Promise((resolve, reject) => {
  loadUserComplete.resolve = resolve
  loadUserComplete.reject = reject
})

// Initial load of the user
function * loadUserSaga () {
  yield take([SET_USER_ID, FETCH_ACCESS_TOKEN_ERROR, REFRESH_TOKEN_ERROR])

  loadUserComplete.resolve()
}

function * bootstrapCookieWorker () {
  yield put(initMediaCookie())
  yield put(initEventCommentsCookie())
  yield put(initInvitationViewedCookie())
}

function * fetchCurrentUserSaga () {
  switch (yield call(accessTokenTypeSaga)) {
    case AccessTokenType.USER:
      yield put(fetchCurrentUser())
      yield put(fetchUserGQ())
      yield put(fetchContactMethod())
      return
    case AccessTokenType.INVITATION:
      yield put(fetchCurrentUser())
      yield put(fetchUserGQ())
      return
    case AccessTokenType.CLIENT:
      yield put(fetchAccessToken())
      const action = yield take(FETCH_ACCESS_TOKEN_SUCCESS)
      setTokens(action.response)
      yield put(fetchCurrentUser())
      yield put(fetchUserGQ())
      yield take([FETCH_CURRENT_USER_SUCCESS, FETCH_CURRENT_USER_ERROR])
  }
}

function * fetchEventSaga (eventToken, invitationToken) {
  const stopRoutes = ['list', 'create', 'createV2']
  if (stopRoutes.indexOf(eventToken) < 0) {
    yield put(fetchEvent(eventToken))
  }
  const action = yield take(FETCH_EVENT_SUCCESS)
  const eventId = action.response.result.event
  const event = action.response.entities.events[eventId]

  if (event.destroyed_at) {
    return
  }

  const destinationType = yield select(destinationTypeSelector)
  if (DESTINATION_TYPES_CONSTANTS[event.event_type] !== destinationType) {
    yield put(setDestinationType(DESTINATION_TYPES_CONSTANTS[event.event_type]))
    updateEventUrl(DESTINATION_TYPES_CONSTANTS[event.event_type], eventToken, invitationToken)
  }

  if (event.event_type === 'announcement') {
    yield put(eventViewCountRequest({ eventId: event.id }))
  }

  yield put(getProcessedMediaCount(event['processed_media_count']))
  yield put(setProcessedPhotoCount(event['visible_photos_count']))
  yield put(setProcessedVideoCount(event['visible_videos_count']))

  // Transitional code
  const flyer = event.flier || event.flyer
  if (flyer) {
    preloadImage(flyer.watermarked_url_2x)
  }

  // Fetch the first batch of media
  yield put(requestFetchMoreMedia())
  if (event.commenting_enabled) {
    yield put(fetchEventComments(event.id))
  }

  yield put(fetchUpdates(event.id))
  yield put(setEventId(event.id))

  yield fork(preloadMediaSaga)
}

function * fetchInvitationSaga (invitationToken, eventToken) {
  // Note this does not handle redirecting the user if they have a deleted invitation
  // Unless they have the invitation token
  // https://www.pivotaltracker.com/story/show/115894713
  let eventsToWaitOn = {}
  let invitation = yield select(invitationSelector)
  if (!invitation && invitationToken && eventToken) {
    yield put(fetchInvitation(invitationToken, eventToken))
    eventsToWaitOn['invitationAction'] = [FETCH_INVITATION_SUCCESS, FETCH_INVITATION_ERROR]
  }

  eventsToWaitOn['eventAction'] = FETCH_EVENT_SUCCESS

  let { invitationAction, eventAction } = yield takeAll(eventsToWaitOn)
  yield loadUserComplete.promise

  if (invitationAction) {
    if (invitationAction.type === FETCH_INVITATION_SUCCESS) {
      invitation = normalizrResult(invitationAction.response, 'invitations', 'invitation')
    }
  }
  let deletedInvitation = invitation && invitation.destroyed_at
  const user = yield select(userSelector)
  if (!deletedInvitation && user) {
    invitation = yield select(currentUsersInvitationSelector)
  }

  if (invitation && !invitation.destroyed_at) {
    yield put(setInvitationId(invitation.id))
    yield put(setTotalAttending(invitation && invitation.rsvp_state === 'accepted'))

    if (hasUserAccessToken()) {
      if (invitation.token) {
        yield put(setInvitationLoadingStatus('success'))
      } else {
        // UGLY, but required to fetch invitation token since event request is possibly fired before auth.
        const event = normalizrResult(eventAction.response, 'events', 'event')
        yield put(fetchInvitation(invitation.id, event.id))
        yield take(FETCH_INVITATION_SUCCESS)
        yield put(setInvitationId(invitation.id))
      }

      // get payment methods list
      yield put(paymentMethodsListRequest())
    } else {
      if (invitation.token) {
        yield put(setInvitationLoadingStatus('success'))
      } else if (invitation.destroyed_at) {
        yield put(setInvitationLoadingStatus('destroyed'))
      } else {
        yield put(setInvitationLoadingStatus('error'))
      }
    }

    yield fork(handleRSVPRouteSaga)
  } else {
    yield put(setInvitationLoadingStatus('not_required'))
    yield put(setInvitationId(''))
    yield put(setTotalAttending(false))
  }
}

function * preloadMediaSaga () {
  while (true) {
    const { apiPayload } = yield take(FETCH_MEDIA_SUCCESS)
    if (!apiPayload.hasMoreEntities) {
      break
    }
  }

  const media = yield select(sortedMediaSelector)
  media.slice(0, 10).forEach((medium) => {
    preloadImage(medium.file.tiny_url_2x)
  })
}

function * setFlyerInitialZoomSaga () {
  const navigating = yield navigatingToSpecificSection()

  if (navigating) {
    yield put(updateFlyerZoom(false))
  }
}

function * initializeOpenInAppModalSaga () {
  const navigating = yield navigatingToSpecificSection()
  if (!getOpenInAppModalClosed() && !isHobnobAndroidClient() && !isHobnobIosClient()) {
    const isHostOrCohost = yield call(hostOrCohost)
    if (!isHostOrCohost && !navigating && window.innerWidth < 1080) {
      yield put(showOpenInAppModal())
    }
  }
}

function * navigatingToSpecificSection () {
  const route = yield select(routeSelector)
  return route === '/event_links' || route === '/join_meeting'
}

function * hostOrCohost () {
  const event = yield select(eventSelector)
  const hostId = event ? event.user_id : null
  const invitation = yield select(invitationSelector)
  const invitationUserId = invitation ? invitation.user : null

  return invitationUserId && (invitationUserId === hostId || (invitation && invitation.cohost))
}
