import PropTypes from 'prop-types'
import React, { Component } from 'react'
import dateformat from 'dateformat'

import HobnobSimpleFormat from 'components/common/HobnobSimpleFormat'

import styles from './event-updates.scss'

export default class EventUpdates extends Component {
  _renderEventUpdates () {
    const { eventUpdates } = this.props

    return eventUpdates.map((eventUpdate) => {
      return (
        <div className={styles['event-update-container']} key={eventUpdate.id}>
          <div className={styles['indicator-column']}>
            <div className={styles['event-update-indicator-circle']} />
            <div className={styles['event-update-indicator-line']} />
          </div>
          <div className={styles['event-update-content-wrapper']}>
            <HobnobSimpleFormat text={eventUpdate.content} paragraphClass={styles['event-update-paragraph']} />
            <div className={styles['event-update-timestamp']}>
              {dateformat(eventUpdate.updated_at, 'mmmm dS, h:MMtt')}
            </div>
          </div>
        </div>
      )
    })
  }

  render () {
    return (
      <div className={styles['container']}>
        <h3 className={styles['header']}>Event Updates</h3>
        <div className={styles['updates-container']}>
          {this._renderEventUpdates()}
        </div>
        <div className={styles['bottom-spacer']} />
      </div>
    )
  }
}

EventUpdates.propTypes = {
  eventUpdates: PropTypes.array
}
