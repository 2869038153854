import React, { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import DashboardContainer from 'src/containers/DashboardContainer'
import BusinessType from './BusinessType/BusinessType'
import BusinessPanel from './BusinessPanel/BusinessPanel'
import { showMessageModal } from 'src/actions/frontend'
import styles from './home-page.scss'

const HomePage = () => {
  const dispatch = useDispatch()
  const containerRef = useRef(null)

  const scrollToTop = () => {
    requestAnimationFrame(() =>
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    )
  }

  const handleMessageModal = () => {
    if (sessionStorage.getItem('openMessage')) {
      dispatch(showMessageModal())
      sessionStorage.removeItem('openMessage')
    }
  }

  useEffect(() => {
    handleMessageModal()

    return () => {
      sessionStorage.removeItem('openMessage')
    }
  }, [])

  return (
    <DashboardContainer menuIcon onClickTitle={scrollToTop}>
      <div className={styles['home-page-container']} ref={containerRef}>
        <BusinessType />
        <BusinessPanel containerRef={containerRef} />
      </div>
    </DashboardContainer>
  )
}

export default HomePage
