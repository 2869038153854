import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styles from '../panel.scss'
import emptyMessageModal from 'src/assets/icons/empty_message_modal.jpg'
import Button from 'src/components/common/Button'
import { DOWNLOAD_APP_LINK } from 'src/constants'

const ChatPanel = () => {
  const userId = useSelector(state => state.userId)

  useEffect(() => {
    window.mixpanel.track('Chat Clicked', { user_id: userId })
  }, [])

  return (
    <div className={styles['menu-panel']}>
      <h3 className={styles['menu-title']}>Chat</h3>
      <div className={styles['menu-panel-content']}>
        <div className={styles['download-app-container']}>
          <img src={emptyMessageModal} className={styles['download-app-image']} />
          <div className={styles['download-app-description']}>
            <h3>To Chat, Get the App</h3>
            <div>
              Hobnob makes group chats pain-free and private! Download Hobnob to direct message any
              guest on the guest list.
            </div>
          </div>
          <Button
            wrapperElement={'a'}
            href={DOWNLOAD_APP_LINK}
            target="_blank"
            className={styles['button']}
            onClick={() => window.mixpanel.track('GET HOBNOB TO CHAT Clicked', { user_id: userId })}
          >
            <span>Get hobnob to chat</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ChatPanel
