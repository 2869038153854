import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { pageLoaded } from 'src/actions'
import DashboardContainer from 'src/containers/DashboardContainer'
import {
  eventSelector,
  eventModalSelector,
  isRefreshModalVisibleSelector,
  chatSelector
} from 'src/selectors'
import NoticeYellow from 'src/assets/icons/ico-notice-yellow.png'
import RSVPModalContainer from 'src/components/rsvp_modal/RSVPModalContainer'
import ImageModalContainer from 'src/components/modals/ImageModalContainer'
import ToastMessagesContainer from 'src/components/global/ToastMessagesContainer'
import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'
import Button from 'components/common/Button'
import EventSection from './EventSection/EventSection'
import FlyerContainer from './FlyerContainer/FlyerContainer'
import { changeRefreshModal } from 'src/actions/event'
import { createSocket, resetSocket } from 'services/websocket'
import { changeChatStatus } from 'src/actions/eventChat'
import { hasUserAccessToken } from 'src/services/access_tokens'

import cn from 'classnames'

import styles from './guest-event.scss'

const GuestEvent = () => {
  const dispatch = useDispatch()
  const event = useSelector(eventSelector)
  const eventModal = useSelector(eventModalSelector)
  const isRefreshModalVisible = useSelector(isRefreshModalVisibleSelector)
  const { socketInstance, chatStatus, channelName } = useSelector(chatSelector)

  const isHasUserAccessToken = hasUserAccessToken()

  useEffect(() => {
    dispatch(pageLoaded())
  }, [])

  useEffect(() => {
    if (isHasUserAccessToken && !socketInstance) {
      resetSocket(dispatch)
      createSocket({ dispatch })
    }
  }, [isHasUserAccessToken])

  useEffect(() => {
    const watchVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log('回到前端页面')
        dispatch(changeChatStatus('connecting'))

        if (socketInstance) {
          window.eventChatTimeout = setTimeout(() => {
            if (chatStatus === 'connecting') {
              dispatch(changeChatStatus('disconnected'))
            }
          }, 5000)

          socketInstance.connect()
        } else {
          createSocket({ dispatch, channelName })
        }
      } else {
        console.log('离开前端页面')
        if (window.eventChatTimeout) {
          clearTimeout(window.eventChatTimeout)
          window.eventChatTimeout = null
        }
        socketInstance.disconnect()
      }
    }

    if (socketInstance) {
      document.addEventListener('visibilitychange', watchVisibilityChange)
    }

    return () => {
      document.removeEventListener('visibilitychange', watchVisibilityChange)
    }
  }, [socketInstance])

  const renderDraftNotice = () => {
    return (
      <div className={styles['draft-notice']}>
        <img src={NoticeYellow} width="20" />
        <span>
          This is a draft preview, activate it to send your invite! (you can edit it anytime)
        </span>
      </div>
    )
  }

  const isDraft = event.state === 'draft'

  return (
    <DashboardContainer
      menuIcon
      visibleHeaderBar={eventModal === 'details'}
      documentTitle={event.name}
    >
      <>
        {isDraft && renderDraftNotice()}

        <div
          className={cn(styles['guest-event-container'], {
            [styles['guest-event-container-draft']]: isDraft
          })}
        >
          <FlyerContainer />
          <div className={styles['information']}>
            <EventSection />
            <RSVPModalContainer />
            <ImageModalContainer />
            <ToastMessagesContainer />
          </div>
        </div>

        <HobnobModal
          visible={isRefreshModalVisible}
          onCancel={() => dispatch(changeRefreshModal(false))}
        >
          <div className={styles['refresh-container']}>
            <div className={styles['title']}>
              Your Host has changed the settings of this event. Please refresh the page to RSVP.
            </div>
            <Button className={styles['button-inner']} onClick={() => window.location.reload()}>
              REFRESH
            </Button>
          </div>
        </HobnobModal>
      </>
    </DashboardContainer>
  )
}

export default GuestEvent
