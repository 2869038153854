import PropTypes from 'prop-types'
import React from 'react'

import Avatar from 'components/common/Avatar'
import UserAvatarContainer from 'components/common/UserAvatarContainer'

import styles from './guest-list-preview.scss'

const MAX_NUM_INVITATIONS = 8
const MIN_NUM_INVITATIONS = 4

export default class GuestListPreview extends React.Component {
  _renderInvitationAvatar = (invitation) => {
    const { users } = this.props
    const invitationUser = users[invitation.guest]
    let avatarUrl = null
    if (invitationUser && invitationUser.avatar) {
      avatarUrl = invitationUser.avatar.small_url_2x
    }

    if (!invitationUser) return null

    return (
      <UserAvatarContainer
        className={styles['avatar']}
        includeBorder={false}
        key={invitation.id}
        url={avatarUrl}
        user={invitationUser}
        size={34}
      />
    )
  }

  // header “WHO’S INVITED” until 5 guests RSVP Yes, then change header to “WHO’S GOING”
  // if guest list/attending is more than 4 show indicator that there are more guests. So 4 avatars and +1 in circle.
  // maximum of 8 avatars, and + circle
  // If there are a full 8 avatars shown, don't show the user's avatar to themselves (but don't hide only the user's avatar)
  render () {
    const { currentInvitation, onClick } = this.props
    const allInvitations = this.props.invitations
    const acceptedInvitations = allInvitations.filter(
      (invitation) => invitation.rsvp_state === 'accepted'
    )
    const showAcceptedInvitations = acceptedInvitations.length >= 5

    const matchingInvitations = showAcceptedInvitations
      ? acceptedInvitations
      : allInvitations
    // Start with the matching invitations
    let numToShow = matchingInvitations.length
    if (numToShow > MIN_NUM_INVITATIONS) {
      // -1 so we can show the +1 indicator
      numToShow -= 1
    }
    if (numToShow > MAX_NUM_INVITATIONS) {
      numToShow = MAX_NUM_INVITATIONS
    }

    // Negative so that we take the most recent invitations
    const invitationsToShow = matchingInvitations
      .filter((invitation) => {
        // Don't show the users' invitation to themselves if there's too many invitations already
        // The +1 is so we are hiding at least one other avatar besides the current user
        if (
          currentInvitation &&
          matchingInvitations.length > MAX_NUM_INVITATIONS + 1
        ) {
          return invitation.id !== currentInvitation.id
        }
        return true
      })
      .slice(-numToShow)

    return (
      <div className={styles['container']}>
        <div className={styles['text']}>
          {showAcceptedInvitations ? "Who's Coming" : "Who's Invited"}
        </div>
        <div className={styles['avatars-container']} onClick={onClick}>
          {invitationsToShow.map(this._renderInvitationAvatar)}
          {numToShow >= MAX_NUM_INVITATIONS &&
          matchingInvitations.length > invitationsToShow.length ? (
            <Avatar
              className={styles['avatar']}
              includeBorder={false}
              size={34}
              textOverride={`+${
                matchingInvitations.length - invitationsToShow.length
              }`}
            />
            ) : null}
        </div>
      </div>
    )
  }
}

GuestListPreview.propTypes = {
  currentInvitation: PropTypes.object,
  invitations: PropTypes.array.isRequired,
  users: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}
