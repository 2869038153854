import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import DashboardContainer from 'containers/DashboardContainer'
import LoadingScreen from 'components/LoadingScreen'
import TextInputElement from 'components/forms/TextInputElement'
import SULOptions from './SULOptions/SULOptions'
import SuccessfulPanel from 'containers/time-poll/panels/SuccessfullyContainer'
import SubscribeContainer from 'containers/time-poll/panels/SubscribeContainer/SubscribeContainer'
import EmailPanel from 'containers/time-poll/panels/EmailContainer'
import ThanksPanel from 'containers/time-poll/panels/ThanksContainer'
import DeletedPanel from 'containers/time-poll/panels/DeletedContainer'
import SULInformation from '../SULInformation/SULInformation'
import { fetchSignUp, list_update } from 'src/actions/timepoll'
import { fetchEventById } from 'src/actions'
import {
  signUpDetailSelector,
  userSelector,
  contactMethodsSelector,
  showLoadingScreenSelector,
  loginFormSelector
} from 'src/selectors'
import PopConfirm from 'components/common/PopConfirm/PopConfirm'
import { message } from 'antd'
import AccountIcon from 'assets/icons/account-icon.png'
import EditIcon from 'assets/icons/edit-icon.png'
import unionCloseIcon from 'assets/icons/union-close-icon.png'
import unionIconGray from 'assets/icons/union-icon-gray.png'
import uuid from 'uuid'
import { routerActions } from 'react-router-redux'
import { showLogin } from 'src/actions/login'
import cn from 'classnames'
import styles from './sul-detail.scss'

function getAnonymousName() {
  const anonymousUser = JSON.parse(localStorage.getItem('anonymousUser'))
  const anonymousId = localStorage.getItem('anonymousId')
  if (anonymousUser && anonymousUser.id === anonymousId) {
    return anonymousUser.name
  }
  return ''
}

const SULDetail = props => {
  const { params } = props
  const dispatch = useDispatch()
  const signUpDetail = useSelector(signUpDetailSelector)
  const contactMethods = useSelector(contactMethodsSelector)
  const subscribeToMailchimpStatus = useSelector(state => state.frontend.subscribeToMailchimpStatus)
  const showLoadingScreen = useSelector(showLoadingScreenSelector)
  const user = useSelector(userSelector)
  const loginForm = useSelector(loginFormSelector)
  const [isCloseSULVisible, setIsCloseSULVisible] = useState(false)
  const [name, setName] = useState(getAnonymousName())
  const [eventDetail, setEventDetail] = useState(null)
  const [updatedStatus, setUpdatedStatus] = useState(false)

  const SULOptionsRef = useRef(null)

  let _isMounted = true

  useEffect(() => {
    if (!localStorage.getItem('anonymousId')) {
      localStorage.setItem('anonymousId', uuid.v4())
    }

    const token = params.signUpToken
    dispatch(
      fetchSignUp({
        token,
        onSuccessCallback: () => {
          if (signUpDetail?.event_id) {
            dispatch(
              fetchEventById({
                ids: [signUpDetail.event_id],
                onSuccessCallback: data => {
                  if (_isMounted) {
                    setEventDetail(data.data.events[0])
                  }
                }
              })
            )
          }
        }
      })
    )

    return () => {
      _isMounted = false
    }
  }, [])

  const setAnonymousName = event => {
    setName(event.target.value)

    localStorage.setItem(
      'anonymousUser',
      JSON.stringify({
        id: localStorage.getItem('anonymousId'),
        name: event.target.value
      })
    )
  }

  const handleClose = () => {
    const list_options_params = signUpDetail.poll_options.map(item => ({
      id: item.id,
      count_limit: item.count_limit,
      description: item.description,
      item: item.item,
      item_rank: item.item_rank
    }))

    dispatch(
      list_update({
        list_params: { id: signUpDetail.id, status: 'closed' },
        list_options_params,
        onSuccessCallback: () => {
          message.success('Closed successfully')
          window.location.reload()
        }
      })
    )
  }

  const getCurrentComponent = () => {
    const backFuc = () => window.location.reload()

    const components = {
      loading: <LoadingScreen />,
      subscribe: <SubscribeContainer handleBackClick={backFuc} />,
      successful: <SuccessfulPanel handleBackClick={backFuc} poll={signUpDetail} />,
      email: <EmailPanel handleBackClick={backFuc} />,
      thanks: <ThanksPanel poll={signUpDetail} handleBackClick={backFuc} />,
      deleted: <DeletedPanel />
    }

    if (!signUpDetail) {
      return components.loading
    }

    if (signUpDetail.destroyed_at) {
      return components.deleted
    }

    if (updatedStatus) {
      if (user && loginForm.loginStep !== 'fullName') {
        if (signUpDetail.email_require) {
          if (
            contactMethods?.isThereAnEmail === false &&
            subscribeToMailchimpStatus !== 'success'
          ) {
            return components.email
          } else if (
            contactMethods?.isThereAnEmail === false &&
            subscribeToMailchimpStatus === 'success'
          ) {
            return components.thanks
          }

          return components.successful
        }

        return components.successful
      } else {
        return components.subscribe
      }
    }

    return null
  }

  const renderButton = type => {
    const isCreator = user?.id === signUpDetail.creator.id
    const isClosed = signUpDetail.status === 'closed'

    if (isClosed) {
      return null
    }

    return (
      <div className={styles[`button-wrapper-${type}`]}>
        {isCreator && (
          <div className={styles['button-inner']} onClick={() => setIsCloseSULVisible(true)}>
            <img src={unionCloseIcon} width="30" />
            <span className={styles['button-text']}>Close List</span>
          </div>
        )}

        <div
          className={cn(styles['button-inner'], styles['button-inner-full'])}
          onClick={() => SULOptionsRef.current.setState({ isOpenFullModal: true })}
        >
          <img src={unionIconGray} width="30" />
          <span className={styles['button-text']}>Full List</span>
        </div>

        {isCreator && (
          <div
            className={cn(styles['button-inner'], styles['button-inner-edit'])}
            onClick={() => dispatch(routerActions.push('/su/edit/' + signUpDetail.token))}
          >
            <img src={EditIcon} width="30" />
            <span>Edit List</span>
          </div>
        )}
      </div>
    )
  }

  const renderNameInput = () => {
    return (
      <div className={styles['name-input-wrapper']}>
        <div className={styles['name-wrapper']}>
          <img src={AccountIcon} alt="" width="18" />
          <TextInputElement
            className={styles['input-inner']}
            placeholder="Enter your name"
            onChange={setAnonymousName}
            valid
            value={name}
          />
        </div>
        <div className={styles['account-wrapper']}>
          <span>Have an account?</span>
          <span
            className={styles['login-text']}
            onClick={() =>
              dispatch(
                showLogin({
                  loginModalSubtitle: 'To vote, please verify your account’s phone number'
                })
              )
            }
          >
            Login here
          </span>
        </div>
      </div>
    )
  }

  const getSignUpDetail = () => {
    return {
      name: signUpDetail.name,
      image: signUpDetail.image_upload?.image_url,
      creator: signUpDetail.creator,
      eventName: eventDetail?.name,
      ends_at: signUpDetail.ends_at,
      createName:
        signUpDetail.custom_creator_name ||
        `${signUpDetail.creator.first_name} ${signUpDetail.creator.last_name}`,
      created_at: signUpDetail.created_at,
      notes: signUpDetail.notes
    }
  }

  const currentComponent = getCurrentComponent()

  if (showLoadingScreen) {
    return <LoadingScreen />
  }

  if (currentComponent) {
    return currentComponent
  }

  return (
    <DashboardContainer documentTitle={signUpDetail.name}>
      <div className={styles['sul-container']}>
        <div className={styles['sul-info-wrapper']}>
          <SULInformation signUpDetail={getSignUpDetail()} />
          {user ? renderButton('web') : renderNameInput()}
        </div>

        <div className={styles['sul-option-wrapper']}>
          <SULOptions
            ref={SULOptionsRef}
            renderButton={renderButton}
            anonymousName={name}
            onUpdateSuccess={() => setUpdatedStatus(true)}
            eventInvitations={eventDetail?.invitations}
          />
        </div>

        <PopConfirm
          visible={isCloseSULVisible}
          confirmText="CLOSE"
          type="delete"
          onClose={() => setIsCloseSULVisible(false)}
          onConfirm={handleClose}
        >
          Are you sure you want to close this sign up list?
        </PopConfirm>
      </div>
    </DashboardContainer>
  )
}

SULDetail.propTypes = {
  params: PropTypes.object
}

export default SULDetail
