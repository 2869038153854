import { put, spawn } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'react-router-redux'

import {
  showFloatingButton
} from 'src/actions/frontend'

import { createWatcher } from './utils'

export default function * frontendRootSaga () {
  yield spawn(createWatcher(LOCATION_CHANGE, floatingButtonWorker))
}

function * floatingButtonWorker () {
  yield put(showFloatingButton())
}
