import React from 'react'
import { useSelector } from 'react-redux'
import { activeMenuPanelSelector } from 'src/selectors'
import ProfilePanel from './ProfilePanel/ProfilePanel'
import ChatPanel from './ChatPanel'
import LoginPanel from 'components/LoginModal/LoginPanel/LoginPanel'
import MembershipDetail from './MembershipDetail/MembershipDetail'

import styles from './../panel.scss'

export const PanelSlug = {
  Membership: 'membership',
  Profile: 'profile',
  Chat: 'chat',
  Login: 'login'
}

const PanelContainer = props => {
  const activeMenuPanel = useSelector(activeMenuPanelSelector)

  const renderPanel = () => {
    switch (activeMenuPanel) {
      case PanelSlug.Profile:
        return <ProfilePanel {...props} />
      case PanelSlug.Chat:
        return <ChatPanel />
      case PanelSlug.Login:
        return (
          <div className={styles['login-panel-container']}>
            <LoginPanel />
          </div>
        )
      case PanelSlug.Membership:
        return <MembershipDetail {...props} />
      default:
        return null
    }
  }

  return <main className={styles['panel-container-main']}>{renderPanel()}</main>
}

export default PanelContainer
