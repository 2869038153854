import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import {
  activeInvitationsSelector,
  createUserForInvitationSelector,
  eventSelector,
  hostSelector,
  invitationSelector,
  isInvitationCredentialUserSelector,
  userSelector,
  usersSelector,
  invitationsAcceptedCountSelector,
  isHostSelector
} from 'src/selectors'

import { EMAIL_CONTACT_METHOD, HOBNOB_USER_CONTACT_METHOD } from 'services/contact_methods'
import { hasUserAccessToken } from 'services/access_tokens'

import {
  showMapModal,
  showRsvpModal,
  updateChangeRsvpStatus,
  showShareModal,
  showHud
} from 'src/actions/frontend'

import { requestAcceptInvitation, requestDeclineInvitation } from 'src/actions/invitations'

import { eventLogout } from 'src/actions/login'

import { hostFullName } from 'src/services/event_helpers'

import FloatingRSVPBtnContainer from 'components/common/FloatingRSVPBtnContainer'
import SvgIcon from 'src/components/common/SvgIcon'
import AscendingAvatar from 'src/components/common/AscendingAvatar'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import EventDateTime from './EventDateTime'
import EventVenue from './EventVenue'
import PrivateInvitationInfo from './PrivateInvitationInfo'
import GuestListPreview from './GuestListPreview'
import Alert from './Alert'

// import ShareEventContainer from 'src/components/details/ShareEventContainer'
import ShareIconHost from 'assets/icons/ico-share-host.png'
import DirectionsIcon from 'src/assets/icons/directions_icon.svg'
import CalendarIcon from 'src/assets/icons/calendar_icon.svg'
import { activateEvent } from 'src/actions/event'

import styles from './invitation-details-container.scss'

export class InvitationDetailsContainer extends Component {
  _renderGuestListPreview() {
    const { invitation, invitations, onShowGuestList, users } = this.props

    return (
      <GuestListPreview
        currentInvitation={invitation}
        users={users}
        invitations={invitations}
        onClick={onShowGuestList}
      />
    )
  }

  _renderVenue() {
    const { event } = this.props
    return (
      <div>
        <EventVenue customVenueName={event.custom_venue_name} venue={event.venue} />
      </div>
    )
  }

  _validateDate() {
    const {
      event: { cutoff_date }
    } = this.props
    if (cutoff_date) {
      return new Date() > new Date(cutoff_date)
    }
    return false
  }

  _validateMaxCapacity() {
    const {
      event: { max_capacity },
      invitationsAcceptedCount
    } = this.props
    if (max_capacity) {
      return invitationsAcceptedCount >= max_capacity
    }
    return false
  }

  _renderEventCalendarAndMap() {
    const { event, onMapClick } = this.props

    return (
      <div className={styles['event-calendar-and-map']}>
        {event.ics_url ? (
          <div className={styles['icon-wrapper']} onClick={this.onCalendarClick}>
            <SvgIcon icon={CalendarIcon} className={styles['map-icon']} />
            <span>Add to Calendar</span>
          </div>
        ) : null}
        {event.venue ? (
          <div className={styles['icon-wrapper']} onClick={onMapClick}>
            <SvgIcon icon={DirectionsIcon} className={styles['map-icon']} />
            <span>Get Directions</span>
          </div>
        ) : null}
      </div>
    )
  }

  onCalendarClick = () => {
    const { event } = this.props
    window.open(event.ics_url)
  }

  handleClickActiveAndShare = () => {
    const { event, invitations, users, onActivateEvent, onShowHud } = this.props
    let count = 0
    if (!event.grandfathered) {
      for (let invitation of invitations) {
        const user = users[invitation.guest]
        const {
          contact_method: { type },
          delivery_state,
          activated_at
        } = invitation
        const { app_user } = user

        if (
          !app_user &&
          type !== EMAIL_CONTACT_METHOD &&
          type !== HOBNOB_USER_CONTACT_METHOD &&
          delivery_state === 'pending' &&
          !!activated_at
        ) {
          count++
        }
      }
    }
    const successActions = [showShareModal(this.getShareEventParams())]
    onActivateEvent({
      eventId: event.id,
      count,
      successActions,
      onErrorCallback: res => {
        const { errors } = res
        if (errors.host[0] === 'user does not have enough credit') {
          onShowHud(
            'error',
            'Activation failed due to insufficeint invite credits.\n\nUpgrade your subscription or remove excess unsent invites from guest list.'
          )
        }
      }
    })
  }

  getShareEventParams = () => {
    const { event, host } = this.props
    const subject =
      event.event_type === 'event' ? `You're invited to ${event.name}` : `Announcing: ${name}`

    const params = {
      title: 'Share Your Event',
      subtitle: 'Guests will add themselves to the Guest List at no extra cost! 💬 💌 📣',
      shareUrl: event.url.substring(8),
      email: {
        event,
        host
      },
      facebook: {
        href: event.facebook_share_url,
        redirect_uri: event.url
      },
      twitter: {
        subject: subject + '!',
        url: event.twitter_share_url
      }
    }

    return params
  }

  handleClickShare = () => {
    const { onShowShareModal, user } = this.props
    window.mixpanel.track('Share Button Clicked', { user_id: user?.id })

    onShowShareModal(this.getShareEventParams())
  }

  render() {
    const {
      host,
      event,
      isAnnouncement,
      invitation,
      invitationUser,
      isInvitationCredentialUser,
      onNotYou,
      user,
      isHost
    } = this.props
    const isOutOfDate = this._validateDate()
    const isOverMaxQuantity = this._validateMaxCapacity()
    const disabled = isOutOfDate || isOverMaxQuantity
    const isLogin = hasUserAccessToken()

    return (
      <div className={styles['container']}>
        <div className={styles['mobile-view']}>
          <AscendingAvatar user={host} size={60} />

          <div className={styles['share-icon-container']}>
            {host?.id === user?.id && event?.state === 'draft' ? (
              <div className={styles['activation-button']} onClick={this.handleClickActiveAndShare}>
                activate & share
              </div>
            ) : (
              <div className={styles['event-share']} onClick={this.handleClickShare}>
                <img src={ShareIconHost} width="20" />
                <span>share</span>
              </div>
            )}
          </div>

          <div className={styles['event-and-host-name']}>
            <div>
              <h2 className={styles['event-name']}>{event.name}</h2>
              <div className={styles['host-name']}>
                {isAnnouncement ? 'From' : 'Host'}: {hostFullName(event, host)}
              </div>
              {isOverMaxQuantity ? (
                <Alert message={event.cap_error_msg} type="warning" />
              ) : (
                isOutOfDate && <Alert message={event.rsvp_error_msg} type="warning" />
              )}
            </div>
            {invitation && !isAnnouncement ? (
              <PrivateInvitationInfo
                isInvitationCredentialUser={isInvitationCredentialUser}
                invitation={invitation}
                invitationUser={invitationUser}
                onNotYou={onNotYou}
                user={user}
              />
            ) : null}
          </div>
        </div>

        <div className={styles['web-view']}>
          <div className={styles['event-title-and-share']}>
            <h1 className={styles['event-name']}>{event.name}</h1>
            {/* <ShareEventContainer /> */}
            {host?.id === user?.id && event?.state === 'draft' ? (
              <div className={styles['activation-button']} onClick={this.handleClickActiveAndShare}>
                activate & share
              </div>
            ) : (
              <div className={styles['event-share']} onClick={this.handleClickShare}>
                <img src={ShareIconHost} width="20" />
                <span>share</span>
              </div>
            )}
          </div>

          <div className={styles['event-and-host-name']}>
            <div className={styles['event-and-host-name__container']}>
              <div className={styles['event-and-host-name__title']}>
                <UserAvatarContainer user={host} size={34} />
                <h3 className={styles['host-name']}>
                  {isAnnouncement ? 'From' : 'Host'}: {hostFullName(event, host)}
                </h3>
              </div>
              {isOverMaxQuantity ? (
                <Alert message={event.cap_error_msg} type="warning" />
              ) : (
                isOutOfDate && <Alert message={event.rsvp_error_msg} type="warning" />
              )}
            </div>
            {invitation && !isAnnouncement ? (
              <PrivateInvitationInfo
                isInvitationCredentialUser={isInvitationCredentialUser}
                invitation={invitation}
                invitationUser={invitationUser}
                onNotYou={onNotYou}
                user={user}
              />
            ) : null}
          </div>
        </div>

        <div className={styles['date-and-venue']}>
          <div className={styles['event-date']}>
            <EventDateTime
              endsAt={event.ends_at}
              startsAt={event.starts_at}
              timezone={event.timezone}
            />
          </div>
          {this._renderVenue()}
        </div>

        {event.venue || event.ics_url ? this._renderEventCalendarAndMap() : null}

        {!isAnnouncement && (
          <FloatingRSVPBtnContainer
            customClassName={isHost && isLogin ? styles['mobile-hidden'] : ''}
            disabled={disabled}
          />
        )}
      </div>
    )
  }
}

InvitationDetailsContainer.propTypes = {
  event: PropTypes.object,
  isAnnouncement: PropTypes.bool,
  host: PropTypes.object,
  isInvitationCredentialUser: PropTypes.bool,
  invitation: PropTypes.object,
  invitations: PropTypes.array,
  invitationUser: PropTypes.object,
  onMapClick: PropTypes.func,
  onNotYou: PropTypes.func,
  onShowGuestList: PropTypes.func,
  user: PropTypes.object,
  users: PropTypes.object,
  invitationsAcceptedCount: PropTypes.number,
  onActivateEvent: PropTypes.func,
  isHost: PropTypes.bool,
  onShowShareModal: PropTypes.func,
  onShowHud: PropTypes.func
}

function mapStateToProps(state) {
  const event = eventSelector(state)
  const host = hostSelector(state)
  const user = userSelector(state)
  const invitation = invitationSelector(state)
  const isAnnouncement = event.event_type === 'announcement'
  let invitationUser
  if (invitation) {
    invitationUser = createUserForInvitationSelector(invitation)(state)
  }

  return {
    event,
    isAnnouncement,
    host,
    user,
    isInvitationCredentialUser: isInvitationCredentialUserSelector(state),
    invitation,
    invitations: activeInvitationsSelector(state),
    invitationUser,
    users: usersSelector(state),
    invitationsAcceptedCount: invitationsAcceptedCountSelector(state),
    isHost: isHostSelector(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onAcceptInvitation: () => dispatch(requestAcceptInvitation()),
    onDeclineInvitation: () => dispatch(requestDeclineInvitation()),
    onChangeRsvp: () => {
      dispatch(routerActions.push('rsvp'))
      dispatch(showRsvpModal())
      dispatch(updateChangeRsvpStatus({ changeRsvp: true }))
    },
    onMapClick: () => dispatch(showMapModal()),
    onNotYou: () => dispatch(eventLogout()),
    onShowGuestList: () => dispatch(routerActions.push('guests')),
    onActivateEvent: params => dispatch(activateEvent(params)),
    onShowShareModal: params => dispatch(showShareModal(params)),
    onShowHud: (hudType, message, callback) => dispatch(showHud(hudType, message, callback))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvitationDetailsContainer)
