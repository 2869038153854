import React, { useState, useEffect, useRef, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { chatSelector, userIdSelector, usersSelector } from 'src/selectors'
import {
  createChatRoom,
  setChannelName,
  addMessage,
  updateMessage,
  deleteMessage,
  changeChatStatus,
  setMemberList,
  resetChat,
  fetchUnreadMessage,
  markReadMessage
} from 'src/actions/eventChat'
import {
  subscribeChannel,
  leaveChannel,
  disconnectSocket,
  pushChannel
} from 'src/services/websocket'
import { ROOM_CHANNEL_TYPES_CONSTANTS, POPUP_BOX_TYPES_CONSTANTS } from 'src/constants'
import { fullName } from 'src/services/user_helpers'
import ChatLoading from '../../../EventChat/ChatLoading/ChatLoading'
import MessageInner from '../../../EventChat/ViewMessage/MessageInner/MessageInner'
import InputMessage from '../../../EventChat/InputMessage/InputMessage'
import ThreadDrawer from '../../../EventChat/ViewMessage/ThreadDrawer/ThreadDrawer'
import BackButton from 'src/assets/icons/back_button.png'
import PageContainerContext from 'src/services/contexts'
import { changeEventsModal } from 'src/actions/event'
import cn from 'classnames'

import styles from './dm-chat.scss'

const DMChat = () => {
  const dispatch = useDispatch()
  const messageSlug = [
    'user_created_message',
    'user_replied_to_thread',
    'user_created_invitation_reply_message',
    'user_created_thread_from_your_message',
    'user_mentioned_you'
  ]

  const { chatUser, socketInstance, channelName, messageList } = useSelector(chatSelector)
  const users = useSelector(usersSelector)
  const userId = useSelector(userIdSelector)
  const { onChangePopupBox, onChangePopupBoxCallBack } = useContext(PageContainerContext)

  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const messagesRef = useRef(null)
  const messagesCountRef = useRef(0)

  useEffect(() => {
    if (isDrawerVisible) {
      history.pushState(null, null, location.href)
      onChangePopupBox(POPUP_BOX_TYPES_CONSTANTS.dmChat)
      onChangePopupBoxCallBack(handleCloseDMChat)
    } else {
      leaveChannel(channelName)
      onChangePopupBox(POPUP_BOX_TYPES_CONSTANTS.guestsList)
      onChangePopupBoxCallBack(() => dispatch(changeEventsModal('details')))
    }
  }, [isDrawerVisible])

  useEffect(() => {
    if (messagesRef.current && messageList.length > messagesCountRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight
    }

    messagesCountRef.current = messageList.length
  }, [messageList.length])

  useEffect(() => {
    if (socketInstance && chatUser && chatUser !== 'allUsers') {
      clearUnreadMessage()
      dispatch(changeChatStatus('connecting'))
      socketInstance.connect()
      setIsDrawerVisible(true)
      dispatch(
        createChatRoom({
          users: [{ id: userId }, chatUser],
          onSuccessCallback: data => {
            const channel = data.group.channel
            setTimeout(() => {
              dispatch(setChannelName(channel))
              subscribeChatChannel(channel)
              pushChatChannel(channel)
            }, 1000)
          }
        })
      )
    }
  }, [chatUser])

  const clearUnreadMessage = () => {
    dispatch(
      fetchUnreadMessage(data => {
        const lists = data.mini_badges.filter(item => messageSlug.includes(item.activity_slug))
        const ids = lists
          .filter(item => {
            const user = item.entities.find(entity => entity.type === 'User')
            return user.id === chatUser.id
          })
          .map(item => item.activity_ids[0])

        dispatch(markReadMessage(ids))
      })
    )
  }

  const subscribeChatChannel = channelName => {
    subscribeChannel({
      channelName,
      dispatch,
      subscriberList: [
        {
          action: ROOM_CHANNEL_TYPES_CONSTANTS.listen_new_message,
          onReceived: ({ message }) => {
            console.log('message:', message)
            dispatch(addMessage(message))
          }
        },
        {
          action: ROOM_CHANNEL_TYPES_CONSTANTS.message_update,
          onReceived: ({ message }) => {
            console.log('message:', message)
            dispatch(updateMessage(message))
          }
        },
        {
          action: ROOM_CHANNEL_TYPES_CONSTANTS.message_read,
          onReceived: data => {
            console.log('data', data)
            // console.log('message:', message)
            // dispatch(updateMessage(message))
          }
        },
        {
          action: ROOM_CHANNEL_TYPES_CONSTANTS.message_destroy,
          onReceived: ({ message }) => {
            console.log('message:', message)
            dispatch(deleteMessage(message))
          }
        }
      ]
    })
  }

  const pushChatChannel = channelName => {
    pushChannel({
      channelName,
      action: ROOM_CHANNEL_TYPES_CONSTANTS.room_members,
      successCallback: data => {
        dispatch(setMemberList(data.room_members))
      }
    })
  }

  const handleCloseDMChat = () => {
    setIsDrawerVisible(false)
    dispatch(resetChat())
    disconnectSocket()
  }

  return (
    <div
      className={cn(
        styles['DM-chat-container'],
        isDrawerVisible && styles['DM-chat-container-show']
      )}
    >
      <div className={styles['drawer-title']}>
        <img src={BackButton} width={20} onClick={() => history.back()} />
        {chatUser && <span>{fullName(chatUser)}</span>}
        <i />
      </div>

      <div className={styles['view-message-body']} ref={messagesRef}>
        {messageList.map(message => (
          <MessageInner key={message.id} message={message} user={users[message.user_id]} />
        ))}
      </div>

      <InputMessage />
      <ThreadDrawer />
      <ChatLoading />
    </div>
  )
}

export default DMChat
