import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { chatSelector } from 'src/selectors'
import UserAvatarContainer from 'src/components/common/UserAvatarContainer'
import { fullName } from 'src/services/user_helpers'
import TextMessage from '../TextMessage/TextMessage'
import GalleryMessage from '../GalleryMessage/GalleryMessage'
import EmojiMessage from '../EmojiMessage/EmojiMessage'
import GifMessage from '../GifMessage/GifMessage'
import LinkMessage from '../LinkMessage/LinkMessage'
import LocationMessage from '../LocationMessage/LocationMessage'
import VideoMessage from '../VideoMessage/VideoMessage'
import InvitationReplyMessage from '../InvitationReplyMessage/InvitationReplyMessage'
import MeatballLinedIcon from 'assets/icons/meatball-lined-icon.png'
import { Popover } from 'antd'
import { setReplyMessage } from 'src/actions/eventChat'

import styles from './user-message.scss'

const UserMessage = props => {
  const dispatch = useDispatch()
  const { message, user, inReply } = props
  const [isOperatePopoverVisible, setIsOperatePopoverVisible] = useState(false)
  const { chatMessages } = useSelector(chatSelector)

  const renderMessageType = () => {
    const { message_parts } = message

    return message_parts.map(message => {
      const { type } = message

      if (type === 'text') {
        return <TextMessage key={message.id} textContent={message} />
      } else if (type === 'link') {
        return <LinkMessage key={message.id} linkContent={message} />
      } else if (type === 'gallery') {
        return <GalleryMessage key={message.id} galleryContent={message} />
      } else if (type === 'giphy') {
        return <GifMessage key={message.id} gifContent={message} />
      } else if (type === 'location') {
        return <LocationMessage key={message.id} locationContent={message} inReply={inReply} />
      } else if (type === 'video' || type === 'album') {
        return <VideoMessage key={message.id} videoContent={message} />
      } else if (type === 'invitation_reply_message') {
        return <InvitationReplyMessage key={message.id} invitationReplyContent={message} />
      }
    })
  }

  const renderOptions = () => {
    let currentMessage = message
    if (message.thread_id) {
      currentMessage = chatMessages[message.thread_id]
    }
    const notReply = currentMessage.replies.filter(reply => !reply.destroyed_at).length === 0

    return (
      <ul className={styles['option-ul']} onClick={() => setIsOperatePopoverVisible(false)}>
        {!inReply && (
          <li
            className={styles['option-li']}
            onClick={() => dispatch(setReplyMessage(currentMessage))}
          >
            {notReply ? 'Start a Thread' : 'Reply to Thread'}
          </li>
        )}
      </ul>
    )
  }

  const calculateTime = () => {
    const { timestamp } = message
    const nowTime = new Date().getTime()
    const time = Math.floor(nowTime / 1000) - timestamp

    if (time < 60) {
      return `- ${time || 1}s`
    } else if (time >= 60 && time < 3600) {
      return `- ${Math.floor(time / 60)}m`
    } else if (time >= 3600 && time < 86400) {
      return `- ${Math.floor(time / 3600)}h`
    } else if (time >= 86400) {
      return `- ${Math.floor(time / 86400)}d`
    }
  }

  return (
    <div className={styles['user-message-container']}>
      <div className={styles['avatar']}>
        <UserAvatarContainer user={user} size={34} />
      </div>
      <div className={styles['message-body']}>
        <div className={styles['user']}>
          <div className={styles['name']}>{fullName(user)}</div>
          {message.message_parts[0].type === 'album' && (
            <div className={styles['time']}> uploaded a video</div>
          )}
          <div className={styles['time']}>{calculateTime()}</div>
        </div>

        {renderMessageType()}

        <div className={styles['message-other']}>
          {!inReply && (
            <Popover
              open={isOperatePopoverVisible}
              overlayClassName={styles['operate-container']}
              trigger="click"
              content={renderOptions()}
              onOpenChange={visible => setIsOperatePopoverVisible(visible)}
            >
              <div className={styles['operate-icon']}>
                <img src={MeatballLinedIcon} width={20} />
              </div>
            </Popover>
          )}
          <EmojiMessage messageId={message.id} reactions={message.reactions} />
        </div>
      </div>
    </div>
  )
}

UserMessage.propTypes = {
  message: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  inReply: PropTypes.bool
}

export default UserMessage
