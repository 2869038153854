import omit from 'lodash/omit'
import { combineReducers } from 'redux'
import { UPDATE_LOCATION } from 'react-router-redux'

import {
  EVENT_NOT_FOUND,
  FETCH_EVENT_ERROR,
  FETCH_EVENT_SUCCESS,
  FETCH_CONTACT_METHOD_SUCCESS
} from 'src/actions'

import {
  FETCH_TIME_POLL_SUCCESS,
  TIME_POLL_NOT_FOUND,
  UPDATE_POLL_USER_SELECTIONS,
  UPDATE_POLL_USER_SELECTIONS_SUCCESS,
  UPDATE_POLL_USER_SELECTIONS_FAILED,
  DELETE_POLL_USER_SELECTIONS,
  DELETE_POLL_USER_SELECTIONS_SUCCESS,
  DELETE_POLL_USER_SELECTIONS_FAILED,
  UPDATE_POLL_USER_SELECTIONS_WHEN_LOGIN,
  UPDATE_POLL_USER_SELECTIONS_WHEN_LOGIN_SUCCESS,
  UPDATE_POLL_USER_SELECTIONS_WHEN_LOGIN_FAILED,
  SUBSCRIBE_TO_MAILCHIMP_SUCCESS,
  SUBSCRIBE_TO_MAILCHIMP_FAILED,
  SUBSCRIBE_TO_MAILCHIMP,
  FETCH_POLL_BY_ID_SUCCESS,
  CREATE_TIME_POLL,
  CREATE_TIME_POLL_SUCCESS,
  CREATE_TIME_POLL_FAILED,
  UPDATE_SELECTED_TIME_POLL_VOTES,
  UPDATE_SELECTED_TIME_POLL_OPTIONS
} from 'src/actions/timepoll'

import {
  HIDE_FLOATING_BUTTON,
  HIDE_LOADING_SCREEN,
  HIDE_MAP_MODAL,
  HIDE_RSVP_MODAL,
  HIDE_TOOLTIP,
  HIDE_LOADING_ICON,
  SHOW_FLOATING_BUTTON,
  SHOW_MAP_MODAL,
  SHOW_LOADING_SCREEN,
  SUBMIT_EVENT_COMMENT,
  UPDATE_EVENT_COMMENT,
  SHOW_LOADING_ICON,
  HIDE_BANNER,
  SHOW_BANNER
} from 'src/actions/frontend'

import { HIDE_LOGIN, SHOW_LOGIN } from 'src/actions/login'

import {
  ACCEPT_RSVP_INITIATED,
  ACCEPT_INVITATION_SUCCESS,
  DECLINE_INVITATION_SUCCESS,
  FETCH_INVITATION_ERROR,
  FETCH_INVITATION_SUCCESS,
  REQUEST_ACCEPT_INVITATION,
  REQUEST_DECLINE_INVITATION,
  SET_INVITATION_LOADING_STATUS,
  SET_INVITATION_REPLY_MESSAGE
} from 'src/actions/invitations'

import {
  FETCH_LIKERS,
  FETCH_LIKERS_ERROR,
  FETCH_LIKERS_SUCCESS,
  FETCH_MEDIA_ERROR,
  FETCH_MEDIA_SUCCESS,
  FETCH_MEDIUM_COMMENTS,
  FETCH_MEDIUM_COMMENTS_ERROR,
  FETCH_MEDIUM_COMMENTS_SUCCESS,
  HIDE_LIKERS_MODAL,
  HIDE_MEDIUM_MORE_TOOLTIP,
  HIDE_MEDIUM_SHARE_TOOLTIP,
  SHOW_MEDIUM_MORE_TOOLTIP,
  SHOW_MEDIUM_SHARE_TOOLTIP,
  SUBMIT_MEDIUM_COMMENT,
  UPDATE_MEDIUM_COMMENT
} from 'src/actions/media'

import {
  EVENT_TICKETS_COUNTS,
  EVENT_TICKETS_COUNTS_ERROR,
  EVENT_TICKETS_COUNTS_SUCCESS,
  SET_REQUESTED_RSVP
} from 'src/actions/paymentGraphql'

import chat from './chat'
import imageModal from './imageModal'
import previewGalleries from './previewGalleries'
import shareModal from './shareModal'
import rsvpModal from './rsvpModal'
import loginPollModal from './loginPollModal'
import loginPoll from './loginPoll'
import menu from './menu'
import chatSettingsModal from './chatSettingsModal'
import likersModal from './likersModal'
import messageModal from './messageModal'
import mediumMoreTooltip from './mediumMoreTooltip'
import mediumShareTooltip from './mediumShareTooltip'
import openInAppModal from './openInAppModal'
import payment from './payment'
import stripeCheckout from './stripeCheckout'
import { pollTab, signUpDetail, signUpForm } from './timePoll'
import {
  eventForm,
  communityMemberList,
  sendUpdateModal,
  eventsTab,
  changeEventFormStatus,
  eventModal,
  addGuestsList,
  invitationsPusher,
  isRefreshModalVisible
} from './event'
import { CHANGE_EVENTS_MODAL } from 'src/actions/event'
import {
  FETCH_USER_GQ_SUCCESS,
  FETCH_ME_COMMUNITY_SUCCESS,
  FETCH_USER_GQ,
  FETCH_CURRENT_USER,
  FETCH_CURRENT_USER_SUCCESS
} from 'src/actions/user'
import plan from './plan'
import globalLayer from './globalLayer'
import flyer from './flyer'

const showLoadingScreen = (state = true, action) => {
  switch (action.type) {
    case HIDE_LOADING_SCREEN:
      return false
    case SHOW_LOADING_SCREEN:
      return true
    default:
      return state
  }
}

const isLoadingVisible = (state = false, action) => {
  switch (action.type) {
    case HIDE_LOADING_ICON:
      return false
    case SHOW_LOADING_ICON:
      return true
    default:
      return state
  }
}

const eventLoadingStatus = (state = 'loading', action) => {
  switch (action.type) {
    case FETCH_EVENT_SUCCESS:
      if (action.response.entities.events[action.response.result.event].destroyed_at) {
        return 'destroyed'
      } else {
        return 'success'
      }
    case FETCH_EVENT_ERROR:
      if (
        action.response &&
        action.response.result.errors &&
        action.response.result.errors.not_found
      ) {
        return 'not_found'
      } else {
        return 'error'
      }
    case EVENT_NOT_FOUND:
      return 'not_found'
    default:
      return state
  }
}

const timePoll = (state = null, action) => {
  switch (action.type) {
    case FETCH_TIME_POLL_SUCCESS:
      if (action.response.result.data.poll_via_token) {
        state = action.response.result.data.poll_via_token
      }
      return state
    default:
      return state
  }
}

const timePollLoadingStatus = (state = 'loading', action) => {
  switch (action.type) {
    case FETCH_TIME_POLL_SUCCESS:
      if (action.response.result.data.poll_via_token) {
        return 'success'
      }
      return 'not_found'
    case TIME_POLL_NOT_FOUND:
      return 'not_found'
    default:
      return state
  }
}

const updatingTimePollStatus = (state = 'none', action) => {
  switch (action.type) {
    case UPDATE_POLL_USER_SELECTIONS:
      return 'loading'
    case UPDATE_POLL_USER_SELECTIONS_SUCCESS:
      if (
        action.response.data &&
        action.response.data.poll_user_selections_update &&
        action.response.data.poll_user_selections_update.successful
      ) {
        return 'success'
      }
      return state
    case UPDATE_POLL_USER_SELECTIONS_FAILED:
      return 'error'
    default:
      return state
  }
}

const contactMethods = (state = null, action) => {
  switch (action.type) {
    case FETCH_CONTACT_METHOD_SUCCESS:
      if (action.response.contact_methods) {
        const response = action.response.contact_methods
        const isThereAnEmail = response.some(el => {
          const keys = Object.keys(el)
          return keys.includes('email_address')
        })
        return {
          ...state,
          isThereAnEmail
        }
      }
      return state
    default:
      return state
  }
}

const updatingTimePollWhenLoginStatus = (state = 'none', action) => {
  switch (action.type) {
    case UPDATE_POLL_USER_SELECTIONS_WHEN_LOGIN:
      return 'loading'
    case UPDATE_POLL_USER_SELECTIONS_WHEN_LOGIN_SUCCESS:
      if (
        action.response.data &&
        action.response.data.poll_user_selections_update &&
        action.response.data.poll_user_selections_update.successful
      ) {
        return 'success'
      }
      return state
    case UPDATE_POLL_USER_SELECTIONS_WHEN_LOGIN_FAILED:
      return 'error'
    default:
      return state
  }
}

const deletingTimePollStatus = (state = 'none', action) => {
  switch (action.type) {
    case DELETE_POLL_USER_SELECTIONS:
      return 'loading'
    case DELETE_POLL_USER_SELECTIONS_SUCCESS:
      if (
        action.response.data &&
        action.response.data.poll_user_selections_update &&
        action.response.data.poll_user_selections_update.successful
      ) {
        return 'success'
      }
      return state
    case DELETE_POLL_USER_SELECTIONS_FAILED:
      return 'error'
    default:
      return state
  }
}

const votes = (state = [], action) => {
  switch (action.type) {
    case UPDATE_POLL_USER_SELECTIONS_SUCCESS:
      if (
        action.response.data &&
        action.response.data.poll_user_selections_update &&
        action.response.data.poll_user_selections_update.successful
      ) {
        return action.response.data.poll_user_selections_update.result
      }
      return state
    default:
      return state
  }
}

const selectedTimePollVotes = (state = [], action) => {
  switch (action.type) {
    case UPDATE_SELECTED_TIME_POLL_VOTES:
      return action.votes
    default:
      return state
  }
}

const selectedTimePollOptions = (state = [], action) => {
  switch (action.type) {
    case UPDATE_SELECTED_TIME_POLL_OPTIONS:
      return action.options
    default:
      return state
  }
}

const customUser = (state = [], action) => {
  switch (action.type) {
    case UPDATE_POLL_USER_SELECTIONS_SUCCESS:
      if (
        action.response.data &&
        action.response.data.poll_user_selections_update &&
        action.response.data.poll_user_selections_update.successful
      ) {
        const options = action.response.data.poll_user_selections_update.result
        const customerUserName = options.find(element => element.custom_user_name !== null)
        return {
          ...state,
          customUserName: customerUserName?.custom_user_name,
          userEmail: '',
          userPhone: ''
        }
      }
      return state
    default:
      return state
  }
}

const subscribeToMailchimpStatus = (state = 'none', action) => {
  switch (action.type) {
    case SUBSCRIBE_TO_MAILCHIMP:
      return 'loading'
    case SUBSCRIBE_TO_MAILCHIMP_SUCCESS:
      return 'success'
    case SUBSCRIBE_TO_MAILCHIMP_FAILED:
      return 'error'
    default:
      return state
  }
}

const pollByIdStatus = (state = [], action) => {
  switch (action.type) {
    case FETCH_POLL_BY_ID_SUCCESS:
      if (action.response.data.polls) {
        state = action.response.data.polls
      }
      return state
    default:
      return state
  }
}

const createTimePollStatus = (state = 'none', action) => {
  switch (action.type) {
    case CREATE_TIME_POLL:
      return 'loading'
    case CREATE_TIME_POLL_SUCCESS:
      return 'success'
    case CREATE_TIME_POLL_FAILED:
      return 'error'
    default:
      return state
  }
}

const invitationLoadingStatus = (state = 'loading', action) => {
  switch (action.type) {
    case SET_INVITATION_LOADING_STATUS:
      return action.status
    case FETCH_INVITATION_SUCCESS:
      if (action.response.entities.invitations[action.response.result.invitation].destroyed_at) {
        return 'destroyed'
      } else {
        return 'success'
      }
    case FETCH_INVITATION_ERROR:
      return 'error'
    default:
      return state
  }
}

const eventTicketCountsLoadingStatus = (state = 'not_spawned', action) => {
  switch (action.type) {
    case EVENT_TICKETS_COUNTS:
      return 'loading'
    case EVENT_TICKETS_COUNTS_SUCCESS:
      return 'success'
    case EVENT_TICKETS_COUNTS_ERROR:
      return 'error'
    default:
      return state
  }
}

const mediaLoadingStatus = (state = 'loading', action) => {
  switch (action.type) {
    case FETCH_MEDIA_SUCCESS:
      if (action.apiPayload.hasMoreEntities) {
        return 'loading'
      }
      return 'success'
    case FETCH_MEDIA_ERROR:
      return 'error'
    default:
      return state
  }
}

const requestedRsvp = (state = '', action) => {
  switch (action.type) {
    case REQUEST_ACCEPT_INVITATION:
    case ACCEPT_RSVP_INITIATED:
      return 'accepted'
    case REQUEST_DECLINE_INVITATION:
      return 'declined'
    case ACCEPT_INVITATION_SUCCESS:
    case DECLINE_INVITATION_SUCCESS:
      // case EVENT_SUBSCRIBE_SUCCESS:
      return ''
    case SET_REQUESTED_RSVP:
      return action.requestedRsvp
    case HIDE_RSVP_MODAL:
      return ''
    default:
      return state
  }
}

const showLogin = (state = false, action) => {
  switch (action.type) {
    case SHOW_LOGIN:
      return true
    case HIDE_LOGIN:
      return false
    default:
      return state
  }
}

const loadingLikers = (state = false, action) => {
  switch (action.type) {
    case FETCH_LIKERS:
      return true
    case FETCH_LIKERS_SUCCESS:
    case FETCH_LIKERS_ERROR:
      return !!action.apiPayload.hasMoreEntities
    case HIDE_LIKERS_MODAL:
      return false
    default:
      return state
  }
}

// We want to show the event details on the intial page load if the user hasn't
// visited another route yet
const showDetailsPanelOnRootRoute = (state = true, action) => {
  switch (action.type) {
    case UPDATE_LOCATION:
      if (action.payload.action !== 'POP') {
        return false
      } else {
        return state
      }
    default:
      return state
  }
}

const mapModalVisible = (state = false, action) => {
  switch (action.type) {
    case SHOW_MAP_MODAL:
      return true
    case HIDE_MAP_MODAL:
      return false
    default:
      return state
  }
}

const invitationReplyMessage = (state = '', action) => {
  switch (action.type) {
    case SET_INVITATION_REPLY_MESSAGE:
      return action.content
    default:
      return state
  }
}

const eventComment = (state = '', action) => {
  switch (action.type) {
    case UPDATE_EVENT_COMMENT:
      return action.content
    case SUBMIT_EVENT_COMMENT:
      return ''
    default:
      return state
  }
}

const tooltipVisible = (state = false, action) => {
  switch (action.type) {
    case SHOW_MEDIUM_MORE_TOOLTIP:
      return true
    case HIDE_MEDIUM_MORE_TOOLTIP:
      return false
    case SHOW_MEDIUM_SHARE_TOOLTIP:
      return true
    case HIDE_MEDIUM_SHARE_TOOLTIP:
      return false
    case HIDE_TOOLTIP:
      return false
    default:
      return state
  }
}

const floatingButtonVisible = (state = true, action) => {
  switch (action.type) {
    case SHOW_FLOATING_BUTTON:
      return true
    case HIDE_FLOATING_BUTTON:
      return false
    default:
      return state
  }
}

const mediaComments = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MEDIUM_COMMENT:
      return {
        ...state,
        [action.mediumId]: action.content
      }
    case SUBMIT_MEDIUM_COMMENT:
      return omit(state, action.payload.comment.commentable_id)
    default:
      return state
  }
}

const mediaCommentsLoadingStatuses = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MEDIUM_COMMENTS:
      if (action.paginationParams.fetchAll) {
        return {
          ...state,
          [action.payload.mediumId]: 'loading'
        }
      } else {
        return state
      }
    case FETCH_MEDIUM_COMMENTS_SUCCESS:
      if (action.originalAction.paginationParams.fetchAll && !action.apiPayload.hasMoreEntities) {
        return {
          ...state,
          [action.originalAction.payload.mediumId]: 'loaded'
        }
      } else {
        return state
      }
    case FETCH_MEDIUM_COMMENTS_ERROR:
      if (action.originalAction.paginationParams.fetchAll) {
        return {
          ...state,
          [action.originalAction.payload.mediumId]: 'error'
        }
      } else {
        return state
      }
    default:
      return state
  }
}

const mediaLikersLoadingStatuses = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LIKERS:
      return {
        ...state,
        [action.payload.mediumId]: 'loading'
      }
    case FETCH_LIKERS_SUCCESS:
      if (!action.apiPayload.hasMoreEntities) {
        return {
          ...state,
          [action.originalAction.payload.mediumId]: 'loaded'
        }
      } else {
        return state
      }
    case FETCH_LIKERS_ERROR:
      if (action.originalAction.paginationParams.fetchAll) {
        return {
          ...state,
          [action.originalAction.payload.mediumId]: 'error'
        }
      } else {
        return state
      }
    default:
      return state
  }
}

const userLoading = (state = false, action) => {
  switch (action.type) {
    case FETCH_USER_GQ:
    case FETCH_CURRENT_USER:
      return true
    case FETCH_USER_GQ_SUCCESS:
    case FETCH_CURRENT_USER_SUCCESS:
      return false
    default:
      return state
  }
}

const communityList = (state = [], action) => {
  switch (action.type) {
    case FETCH_ME_COMMUNITY_SUCCESS:
      const communities = action.response?.data?.me?.communities || []
      const filteredCommunities = communities.filter(
        community =>
          !community.destroyed_at &&
          community.community_members &&
          community.community_members.length > 0
      )
      return filteredCommunities
    default:
      return state
  }
}

const defaultBanner = {
  visible: true,
  title: 'Download Hobnob',
  text: 'Don’t miss a host update!'
}

const topBanner = (state = defaultBanner, action) => {
  switch (action.type) {
    case SHOW_BANNER:
      return { ...state, visible: true }
    case HIDE_BANNER:
      return { ...state, visible: false }
    case CHANGE_EVENTS_MODAL:
      if (action.value === 'media') {
        return {
          visible: state.visible,
          title: 'Download Hobnob app',
          text: 'to share event videos'
        }
      } else {
        return { ...defaultBanner, visible: state.visible }
      }
    default:
      return state
  }
}

export default combineReducers({
  globalLayer,
  plan,
  chat,
  flyer,
  previewGalleries,
  imageModal,
  shareModal,
  rsvpModal,
  menu,
  chatSettingsModal,
  likersModal,
  messageModal,
  mediumMoreTooltip,
  pollByIdStatus,
  mediumShareTooltip,
  openInAppModal,
  payment,
  showLoadingScreen,
  isLoadingVisible,
  eventLoadingStatus,
  timePoll,
  timePollLoadingStatus,
  subscribeToMailchimpStatus,
  createTimePollStatus,
  updatingTimePollStatus,
  updatingTimePollWhenLoginStatus,
  deletingTimePollStatus,
  votes,
  selectedTimePollVotes,
  selectedTimePollOptions,
  invitationLoadingStatus,
  eventTicketCountsLoadingStatus,
  requestedRsvp,
  invitationReplyMessage,
  mapModalVisible,
  mediaLoadingStatus,
  loadingLikers,
  showDetailsPanelOnRootRoute,
  showLogin,
  tooltipVisible,
  floatingButtonVisible,
  eventComment,
  mediaComments,
  mediaCommentsLoadingStatuses,
  mediaLikersLoadingStatuses,
  customUser,
  loginPollModal,
  loginPoll,
  contactMethods,
  pollTab,
  signUpDetail,
  signUpForm,
  eventForm,
  communityMemberList,
  sendUpdateModal,
  eventsTab,
  eventModal,
  addGuestsList,
  invitationsPusher,
  changeEventFormStatus,
  stripeCheckout,
  communityList,
  isRefreshModalVisible,
  topBanner,
  userLoading
})
