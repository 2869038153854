import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { routerActions } from 'react-router-redux'

import {
  activeLinksSelector,
  eventSelector,
  eventTokenSelector,
  eventUpdatesSelector,
  hasPaidTicketsSelector,
  hostSelector,
  invitationTokenSelector,
  isHostOrCohostSelector,
  mapModalVisibleSelector,
  routeSelector,
  ticketTypesSelector,
  destinationTypeSelector
} from 'src/selectors'
import { hasUserAccessToken } from 'services/access_tokens'

import { hideMapModal, showHostMessageModal } from 'src/actions/frontend'

import { hostFullName } from 'src/services/event_helpers'

import { androidObject, isHobnobAndroidClient, sortByCreatedAtComparator } from 'src/services/utils'
import { ticketTypeHasDescription } from 'src/services/payment_utils'

import InvitationDetailsContainer from 'src/components/details/InvitationDetailsContainer'
import EventDetails from 'src/components/details/EventDetails'
import JoinMeetingSection from 'src/components/details/JoinMeetingSection'
import EventUpdates from 'src/components/details/EventUpdates'
import EventLinksContainer from 'src/components/details/EventLinksContainer'
import MapModal from 'src/components/details/MapModal'
import GuestChatPhotoCell from 'containers/event/EventDetail/GuestChatPhotoCell/GuestChatPhotoCell'
import LegalNotice from 'src/components/details/LegalNotice'
import Flier from 'components/details/Flier'
import FloatingButtonContainer from 'components/common/FloatingButtonContainer'
import HostOptions from 'containers/event/EventDetail/HostOptions/HostOptions'
import SignUpListContainer from 'components/details/SignUpListContainer/SignUpListContainer'
import styles from './event-details-page.scss'
import EventPayment from 'components/details/EventPayment'
import { messageContainerRequest } from 'src/actions/graphql2'
import { fetchEvent } from 'src/actions'

const EventDetailPage = props => {
  const { onChange } = props
  const dispatch = useDispatch()
  const event = useSelector(eventSelector)
  const eventToken = useSelector(eventTokenSelector)
  const isAnnouncement = event.event_type === 'announcement'
  const eventUpdates = useSelector(state =>
    Object.values(eventUpdatesSelector(state))
      .filter(u => !u.destroyed_at)
      .sort(sortByCreatedAtComparator(true))
  )
  const host = useSelector(hostSelector)
  const invitationToken = useSelector(invitationTokenSelector)
  const isHostOrCohost = useSelector(isHostOrCohostSelector)
  const mapModalVisible = useSelector(mapModalVisibleSelector)
  const ticketTypes = useSelector(ticketTypesSelector) || []
  const destinationType = useSelector(destinationTypeSelector)
  const route = useSelector(routeSelector)
  const isPaidEvent = useSelector(hasPaidTicketsSelector)
  const links = useSelector(activeLinksSelector)

  useEffect(() => {
    dispatch(messageContainerRequest('Event', event.id))
    dispatch(fetchEvent(eventToken))
  }, [])

  const _onCloseMapModal = useCallback(() => {
    let url = `/${destinationType}/${eventToken}/`

    if (invitationToken) {
      url += `${invitationToken}/`
    }
    dispatch(routerActions.replace(url))
    dispatch(hideMapModal())
  }, [destinationType, eventToken, invitationToken, dispatch])

  const _onMessageHostClick = useCallback(() => {
    const data = {
      title: 'To Chat, Get the App',
      subTitle: `Join ${hostFullName(event, host)} on the Hobnob app to send a message.`
    }

    if (isHobnobAndroidClient()) {
      androidObject().launchChatActivity(host.id)
    } else {
      dispatch(showHostMessageModal(data))
    }
  }, [dispatch, event, host])

  const _showMessageHostButton = () => {
    return !isHostOrCohost
  }

  const _renderEventDetails = () => {
    const showMessageHostButton = _showMessageHostButton()

    return (
      <div>
        <EventDetails
          event={event}
          isAnnouncement={isAnnouncement}
          onMessageHostClick={_onMessageHostClick}
          showMessageHostButton={showMessageHostButton}
        />
      </div>
    )
  }

  const _renderEventUpdates = () => {
    return (
      <div>
        <EventUpdates eventUpdates={eventUpdates} />
      </div>
    )
  }

  const _scrollTo = ref => {
    if (route === '/event_links' || route === '/join_meeting') {
      window.scrollTo(0, ref.offsetTop)
    }
  }

  const _renderEventLinks = () => {
    return (
      <div>
        <EventLinksContainer scrollTo={_scrollTo} />
      </div>
    )
  }

  const _renderEventGuestsChatPhotoCell = () => {
    return (
      <div>
        <GuestChatPhotoCell event={event} onChange={onChange} />
      </div>
    )
  }

  const _renderEventPayments = () => {
    const showTicketTypes = isPaidEvent || ticketTypeHasDescription(ticketTypes)
    if (!showTicketTypes) return null

    return (
      <div>
        <EventPayment ticketTypes={ticketTypes} event={event} />
      </div>
    )
  }

  const _renderHostOptions = () => {
    return <HostOptions />
  }

  const _renderSignUpList = () => {
    let visible = true
    if (event.lists?.length === 0 && !isHostOrCohost) {
      visible = false
    }

    return visible ? <SignUpListContainer /> : null
  }

  return (
    <div className={styles['container']}>
      <div className={styles['event-detail-flier']}>
        <Flier event={event} />
      </div>
      <InvitationDetailsContainer />
      <JoinMeetingSection event={event} scrollTo={_scrollTo} />

      {event.ticketing_enabled_at &&
        !!ticketTypes &&
        !!ticketTypes.length &&
        !isAnnouncement &&
        _renderEventPayments()}

      {_renderSignUpList()}

      {event.description || _showMessageHostButton() ? _renderEventDetails() : null}

      {!isAnnouncement && _renderEventGuestsChatPhotoCell()}

      {eventUpdates.length > 0 ? _renderEventUpdates() : null}

      {links.length > 0 ? _renderEventLinks() : null}

      {isHostOrCohost && hasUserAccessToken() && _renderHostOptions()}

      <div className={styles['legal-notice-container']}>
        {!isHobnobAndroidClient() && <LegalNotice />}
      </div>
      {!isAnnouncement && (
        <FloatingButtonContainer pageName="Details Page" style={{ bottom: '105px' }} />
      )}
      <MapModal
        customVenueName={event.custom_venue_name}
        venue={event.venue}
        onClose={_onCloseMapModal}
        show={mapModalVisible}
      />
    </div>
  )
}

EventDetailPage.propTypes = {
  onChange: PropTypes.func
}

export default EventDetailPage
