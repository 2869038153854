import React, { Component } from 'react'
import DashboardContainer from 'containers/DashboardContainer'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchSignUp, changeSignUpForm } from 'src/actions/timepoll'
import { signUpDetailSelector } from 'src/selectors'
import { fetchEventById } from 'src/actions'
import { parseEventUrl } from 'services/url_helpers'
import { routerActions } from 'react-router-redux'

import SettingWrapper from '../CreateSignUp/SettingWrapper/SettingWrapper'
import { deepCopyArray } from 'src/services/utils'

import SettingIcon from 'assets/icons/setting-icon.png'

import InputOptions from './InputOptions/InputOptions'
import InputInformation from './InputInformation/InputInformation'

import cn from 'classnames'
import styles from './edit-sul.scss'

class EditSUL extends Component {
  state = {
    isSettingVisible: false,
    imageUrl: '',
    list_options_params: [],
    SULToken: parseEventUrl(window.location.pathname).invitationToken
  }

  fetchSULDetail = () => {
    const { onGetSignUpDetails, onGetEventDetails, onChangeSignUpForm } = this.props
    onGetSignUpDetails({
      token: this.state.SULToken,
      onSuccessCallback: res => {
        const signUpDetail = res.data.list_via_token

        if (signUpDetail?.event_id) {
          onGetEventDetails({
            ids: [signUpDetail.event_id],
            onSuccessCallback: data => {
              onChangeSignUpForm({ checkedEvent: data.data.events[0] })
            }
          })
        }

        onChangeSignUpForm(signUpDetail)

        this.setState({
          imageUrl: signUpDetail.image_upload.image_url,
          list_options_params: deepCopyArray(signUpDetail.poll_options)
        })
      }
    })
  }

  getSignUpForm = () => {
    const { signUpForm, goBack } = this.props
    if (!signUpForm.checkedImage) {
      goBack()
      return
    }

    this.setState({
      imageUrl: signUpForm.checkedImage.url,
      list_options_params: deepCopyArray(signUpForm.list_options_params)
    })
  }

  componentDidMount() {
    this._isMounted = true
    if (this.state.SULToken) {
      this.fetchSULDetail()
    } else {
      this.getSignUpForm()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { imageUrl, list_options_params, isSettingVisible } = this.state
    const { goBack } = this.props
    return (
      <DashboardContainer
        title="Edit Lists"
        right={
          <img
            src={SettingIcon}
            onClick={() => this.setState({ isSettingVisible: true })}
            width="18"
          />
        }
      >
        <div className={cn(styles['container'], isSettingVisible && [styles['overflow-y-hidden']])}>
          <InputInformation imageUrl={imageUrl} goBack={goBack} />
          <InputOptions
            goBack={goBack}
            onOpenSetting={() => this.setState({ isSettingVisible: true })}
            list_options_params={list_options_params}
          />
        </div>

        {isSettingVisible && (
          <div className={styles['setting-wrapper']}>
            <SettingWrapper onClose={() => this.setState({ isSettingVisible: false })} />
          </div>
        )}
      </DashboardContainer>
    )
  }
}

EditSUL.propTypes = {
  onGetSignUpDetails: PropTypes.func,
  onGetEventDetails: PropTypes.func,
  goBack: PropTypes.func,
  signUpForm: PropTypes.object,
  onChangeSignUpForm: PropTypes.func
}

function mapStateToProps(state) {
  return {
    signUpDetail: signUpDetailSelector(state),
    signUpForm: state.frontend.signUpForm
  }
}
function mapDispatchToProps(dispatch) {
  return {
    onGetSignUpDetails: params => dispatch(fetchSignUp(params)),
    onGetEventDetails: params => dispatch(fetchEventById(params)),
    goBack: () => dispatch(routerActions.goBack()),
    onChangeSignUpForm: params => dispatch(changeSignUpForm(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSUL)
