import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import LoadingComponent from 'src/components/common/LoadingComponent/LoadingComponent'
import PageContainerContext from 'services/contexts'
import { POPUP_BOX_TYPES_CONSTANTS, DESTINATION_TYPES_CONSTANTS } from './constants'
import { fetchMeWithCommunity, fetchUserGQ } from 'src/actions/user'
// import StripeCheckoutContainer from 'containers/StripeCheckoutContainer/StripeCheckoutContainerV2'
import LoginModal from 'src/components/LoginModal/LoginModal'
import { parseEventUrl } from 'services/url_helpers'
import {
  clearStripeCheckoutTimeoutHandle,
  StripeCheckoutBroadcastChannelKey
} from 'src/actions/stripeCheckout'
import {
  stripeCheckoutSelector,
  userIdSelector,
  isLoadingVisibleSelector,
  destinationTypeSelector,
  eventTokenSelector,
  previewGalleriesSelector
} from 'src/selectors'
import { clearUTMData } from 'services/stripe_checkout_configs'
import { isSafariLessThan14, safariHacks, isMobile } from './services/browser_detection'
import { Modal, Image } from 'antd'
import { debounce } from 'lodash'
import ShareModal from 'components/ShareModal/ShareModal'
import data from '@emoji-mart/data'
import { init } from 'emoji-mart'
import { hideBanner, setPreviewIndex } from 'src/actions/frontend'
import HobnobHud from 'src/components/common/HobnobHud'
import MessageModalContainer from 'src/components/modals/MessageModalContainer'
import GlobalLayer from 'containers/GlobalLayer/GlobalLayer'
import { fetchEvent } from 'src/actions'

const PageContainer = ({ children }) => {
  const dispatch = useDispatch()
  const isLoadingVisible = useSelector(isLoadingVisibleSelector)
  const eventModal = useSelector(state => state.frontend.eventModal)
  const checkoutContext = useSelector(stripeCheckoutSelector)
  const userId = useSelector(userIdSelector)
  const destinationType = useSelector(destinationTypeSelector)
  const eventToken = useSelector(eventTokenSelector)
  const previewGalleries = useSelector(previewGalleriesSelector)

  const popupBoxName = useRef(null)
  const popupBoxCallBack = useRef(null)
  const storageCallback = useRef(null)

  const handleBanner = () => {
    if (sessionStorage.getItem('hideBanner')) {
      dispatch(hideBanner())
    }
  }

  const handleIOS14 = () => {
    if (isSafariLessThan14()) {
      Modal.info({
        icon: null,
        centered: true,
        className: 'low-ios-err-dlg',
        content: (
          <>
            <div>You are on an old iOS version that doesn’t support some Hobnob web functions.</div>
            <div>Please upgrade your iOS version and try again.</div>
          </>
        )
      })
    }
  }

  const handleResize = debounce(() => {
    if (!isMobile()) {
      safariHacks()
    }
  }, 500)

  useEffect(() => {
    const verifyPopupBox = () => {
      const { destinationType } = parseEventUrl(window.location.pathname)

      switch (destinationType) {
        case DESTINATION_TYPES_CONSTANTS.event:
          const modals = ['addGuests', 'guests', 'chat']
          if (modals.includes(eventModal)) {
            return true
          }
          return false
        default:
          return false
      }
    }

    const watchPopState = () => {
      const popupBoxes = Object.values(POPUP_BOX_TYPES_CONSTANTS)
      if (
        verifyPopupBox() &&
        popupBoxes.includes(popupBoxName.current) &&
        popupBoxCallBack.current
      ) {
        popupBoxCallBack.current()
      }
    }

    window.addEventListener('popstate', watchPopState)

    return () => {
      window.removeEventListener('popstate', watchPopState)
    }
  }, [eventModal])

  useEffect(() => {
    const watchStorage = event => {
      if (event.key === StripeCheckoutBroadcastChannelKey) {
        const data = JSON.parse(event.newValue)

        if (storageCallback.current) {
          storageCallback.current(data)
        }

        if (data && !data?.hasError && (data.planUpdated || data.creditsUpdated)) {
          checkoutContext.openedTabRef?.close()
          clearUTMData()
          dispatch(fetchUserGQ())

          // If it is in an event, the event data should be reloaded.
          if (destinationType === DESTINATION_TYPES_CONSTANTS.event) {
            dispatch(fetchEvent(eventToken))
            return
          }
        }

        dispatch(clearStripeCheckoutTimeoutHandle())
        localStorage.removeItem(StripeCheckoutBroadcastChannelKey)
      }
    }

    window.addEventListener('storage', watchStorage)

    return () => window.removeEventListener('storage', watchStorage)
  }, [checkoutContext])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    dispatch(fetchMeWithCommunity())
    handleIOS14()
    safariHacks()
    handleBanner()
    init({ data })

    if (window.location.href.includes('/home')) {
      setTimeout(() => {
        if (window.mixpanel) {
          window.mixpanel.track('User From Search', { user_id: userId })
        }
      }, 2000)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <PageContainerContext.Provider
      value={{
        onChangePopupBox: name => (popupBoxName.current = name),
        onChangePopupBoxCallBack: callback => (popupBoxCallBack.current = callback),
        onSetStorageCallback: callback => (storageCallback.current = callback)
      }}
    >
      {children}
      {isLoadingVisible && <LoadingComponent dark />}
      <svg style={{ visibility: 'hidden', height: 0, width: 0 }}>
        <defs>
          <linearGradient id="hobnob-gradient" x1="100%" y1="100%" x2="0%">
            <stop stopColor="#55EFCB" />
            <stop offset="1" stopColor="#99E09C" />
          </linearGradient>
        </defs>
      </svg>

      <LoginModal />
      <ShareModal />
      <HobnobHud />
      <MessageModalContainer />
      <GlobalLayer />
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            visible: Boolean(previewGalleries.index > -1),
            onVisibleChange: () => {
              dispatch(setPreviewIndex(-1))
            },
            current: previewGalleries.index
          }}
        >
          {previewGalleries.galleries.map(img => (
            <Image key={img.id} src={img.url} />
          ))}
        </Image.PreviewGroup>
      </div>
    </PageContainerContext.Provider>
  )
}

PageContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export default PageContainer
