import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { hideLogin, updateLoginForm } from 'src/actions/login'
import styles from './login-modal.scss'
import { loginModalVisibleSelector, userSelector, loginFormSelector } from 'src/selectors'

// import LoginPanel from 'components/LoginPanel/LoginPanel'
import LoginPanel from './LoginPanel/LoginPanel'

import HobnobModal from 'components/common/HobnobModalV2/HobnobModal'

const LoginModal = () => {
  const isLoginModalVisible = useSelector(loginModalVisibleSelector)
  const user = useSelector(userSelector)
  const loginForm = useSelector(loginFormSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user && loginForm.loginInRSVP) {
      const {
        primary_contact_method: { phone_number }
      } = user

      dispatch(
        updateLoginForm({
          phoneNumber: phone_number,
          formattedPhoneNumber: phone_number?.slice(1)
        })
      )
    }
  }, [user, loginForm.loginInRSVP])

  return (
    <HobnobModal
      visible={isLoginModalVisible}
      destroyOnClose
      onCancel={() => {
        dispatch(hideLogin(true))
      }}
      maskClosable={false}
    >
      <div className={styles['login-modal-container']}>
        <LoginPanel />
      </div>
    </HobnobModal>
  )
}

export default LoginModal
