import {
  EMAIL_CONTACT_METHOD,
  buildEmailContactMethod,
  buildPhoneNumberContactMethod
} from 'src/services/contact_methods'

export function fullName (user, namesByUserId = {}) {
  let firstName = user.first_name
  let lastName = user.last_name
  let nameEntity = namesByUserId[user.id]

  if (nameEntity && nameEntity.display_name) {
    return nameEntity.display_name
  } else if (nameEntity) {
    firstName = nameEntity.first_name
    lastName = nameEntity.last_name
  }

  let name
  if (firstName) {
    name = firstName
    if (lastName) {
      name += ` ${lastName}`
    }
  } else {
    name = lastName
  }

  return name
}

export function firstName (user, namesByUserId = {}) {
  let firstName = user.first_name
  let lastName = user.last_name
  let displayName = null

  if (namesByUserId[user.id]) {
    firstName = namesByUserId[user.id].first_name
    lastName = namesByUserId[user.id].last_name
    displayName = namesByUserId[user.id].display_name
  }

  return firstName || lastName || displayName
}

export function buildUser (loginForm, contactMethod) {
  const { firstName, lastName } = loginForm
  const userContactMethod = buildContactMethod(loginForm, contactMethod)

  return {
    first_name: firstName,
    last_name: lastName,
    primary_contact_method_attributes: userContactMethod
  }
}

function buildContactMethod (loginForm, contactMethod) {
  if (contactMethod && contactMethod.type === EMAIL_CONTACT_METHOD) {
    return buildEmailContactMethod(loginForm.emailAddress)
  } else {
    return buildPhoneNumberContactMethod(loginForm.phoneNumber, loginForm.countryCode)
  }
}
