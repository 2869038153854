import { fetchApi } from './api'
import uuid from 'uuid'
import { EventUpdate, Event } from 'src/schemas'
import { formatDoubleQuotes } from './utils'
import { showHud } from './frontend'

export const CHANGE_EVENT_FORM = 'CHANGE_EVENT_FORM'
export const RESET_EVENT_FORM = 'RESET_EVENT_FORM'
export const CHANGE_EVENTS_TAB = 'CHANGE_EVENTS_TAB'
export const CHANGE_EVENTS_MODAL = 'CHANGE_EVENTS_MODAL'
export const CHANGE_ADD_GUESTS_LIST = 'CHANGE_ADD_GUESTS_LIST'

export const FETCH_CREATE_EVENT = 'FETCH_CREATE_EVENT'
export const FETCH_CREATE_EVENT_SUCCESS = 'FETCH_CREATE_EVENT_SUCCESS'
export const FETCH_CREATE_EVENT_ERROR = 'FETCH_CREATE_EVENT_ERROR'

export const FETCH_UPLOAD_FLYER = 'FETCH_UPLOAD_FLYER'
export const FETCH_UPLOAD_FLYER_SUCCESS = 'FETCH_UPLOAD_FLYER_SUCCESS'
export const FETCH_UPLOAD_FLYER_ERROR = 'FETCH_UPLOAD_FLYER_ERROR'

export const FETCH_ME_COMMUNITIES = 'FETCH_ME_COMMUNITIES'
export const FETCH_ME_COMMUNITIES_SUCCESS = 'FETCH_ME_COMMUNITIES_SUCCESS'
export const FETCH_ME_COMMUNITIES_ERROR = 'FETCH_ME_COMMUNITIES_ERROR'

export const ACTIVATE_EVENT = 'ACTIVATE_EVENT'
export const ACTIVATE_EVENT_SUCCESS = 'ACTIVATE_EVENT_SUCCESS'
export const ACTIVATE_EVENT_ERROR = 'ACTIVATE_EVENT_ERROR'

export const SEND_UPDATE_EVENT = 'SEND_UPDATE_EVENT'
export const SEND_UPDATE_EVENT_SUCCESS = 'SEND_UPDATE_EVENT_SUCCESS'
export const SEND_UPDATE_EVENT_ERROR = 'SEND_UPDATE_EVENT_ERROR'

export const DELETE_EVENT = 'DELETE_EVENT'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR'

export const CLONE_EVENT = 'CLONE_EVENT'
export const CLONE_EVENT_SUCCESS = 'CLONE_EVENT_SUCCESS'
export const CLONE_EVENT_ERROR = 'CLONE_EVENT_ERROR'

export const ARCHIVE_EVENT = 'ARCHIVE_EVENT'
export const ARCHIVE_EVENT_SUCCESS = 'ARCHIVE_EVENT_SUCCESS'
export const ARCHIVE_EVENT_ERROR = 'ARCHIVE_EVENT_ERROR'

export const ARCHIVE_TICKET_TYPE = 'ARCHIVE_TICKET_TYPE'
export const ARCHIVE_TICKET_TYPE_SUCCESS = 'ARCHIVE_TICKET_TYPE_SUCCESS'
export const ARCHIVE_TICKET_TYPE_ERROR = 'ARCHIVE_TICKET_TYPE_ERROR'

export const CREATE_TICKET_TYPE = 'CREATE_TICKET_TYPE'
export const CREATE_TICKET_TYPE_SUCCESS = 'CREATE_TICKET_TYPE_SUCCESS'
export const CREATE_TICKET_TYPE_ERROR = 'CREATE_TICKET_TYPE_ERROR'

export const PARSE_EVENT_CSV = 'PARSE_EVENT_CSV'
export const PARSE_EVENT_CSV_SUCCESS = 'PARSE_EVENT_CSV_SUCCESS'
export const PARSE_EVENT_CSV_ERROR = 'PARSE_EVENT_CSV_ERROR'

export const FETCH_INVITATIONS_BULK = 'FETCH_INVITATIONS_BULK'
export const FETCH_INVITATIONS_BULK_SUCCESS = 'FETCH_INVITATIONS_BULK_SUCCESS'
export const FETCH_INVITATIONS_BULK_ERROR = 'FETCH_INVITATIONS_BULK_ERROR'

export const FETCH_EVENT_LIST = 'FETCH_EVENT_LIST'
export const FETCH_EVENT_LIST_SUCCESS = 'FETCH_EVENT_LIST_SUCCESS'
export const FETCH_EVENT_LIST_ERROR = 'FETCH_EVENT_LIST_ERROR'

export const RESET_INVITATIONS_PUSHER = 'RESET_INVITATIONS_PUSHER'

export const CHANGE_REFRESH_MODAL = 'CHANGE_REFRESH_MODAL'

export function changeEventForm(params) {
  return { type: CHANGE_EVENT_FORM, params }
}

export function resetEventForm() {
  return { type: RESET_EVENT_FORM }
}

export function changeEventsTab(params) {
  return { type: CHANGE_EVENTS_TAB, value: params }
}

export function changeEventsModal(params) {
  return { type: CHANGE_EVENTS_MODAL, value: params }
}

export function resetInvitationsPusher() {
  return { type: RESET_INVITATIONS_PUSHER }
}

export function changeRefreshModal(params) {
  return { type: CHANGE_REFRESH_MODAL, value: params }
}

export function changeAddGuestsList(params) {
  const map = new Map()
  const deduplicationArray = params.filter(item => {
    if (item.userFromHobnobContacts) return true
    const key = `${item.contact_method_display_name || 'empty'}`
    return !map.has(key) && map.set(key, 1)
  })
  return { type: CHANGE_ADD_GUESTS_LIST, value: deduplicationArray }
}

export function fetchCreateEvent({ eventId, eventForm, onSuccessCallback, onErrorCallback }) {
  return fetchApi({
    requestType: FETCH_CREATE_EVENT,
    successType: FETCH_CREATE_EVENT_SUCCESS,
    errorType: FETCH_CREATE_EVENT_ERROR,
    route: `/events/${eventId || uuid.v4()}`,
    options: {
      method: eventId ? 'PATCH' : 'PUT',
      body: JSON.stringify({ event: eventForm })
    },
    onSuccessCallback,
    onErrorCallback
  })
}

export function deleteEvent({ eventId, onSuccessCallback }) {
  return fetchApi({
    requestType: DELETE_EVENT,
    successType: DELETE_EVENT_SUCCESS,
    errorType: DELETE_EVENT_ERROR,
    route: `/events/${eventId}`,
    options: {
      method: 'DELETE'
    },
    onSuccessCallback
  })
}

export function cloneEvent({ eventId, onSuccessCallback }) {
  return fetchApi({
    requestType: CLONE_EVENT,
    successType: CLONE_EVENT_SUCCESS,
    errorType: CLONE_EVENT_ERROR,
    route: `/events/${eventId}/clone`,
    options: {
      method: 'POST'
    },
    onSuccessCallback
  })
}

export function fetchUploadFlyer({
  eventId,
  design_number,
  customBgDataURL,
  onSuccessCallback,
  onErrorCallback
}) {
  const requestParams = {
    image_flyer: {
      design_number,
      image_upload_attributes: {
        mimetype: 'image/png',
        id: uuid.v4()
      }
    }
  }
  if (customBgDataURL) {
    requestParams.image_flyer.image_bg_upload_attributes = {
      mimetype: 'image/png',
      id: uuid.v4()
    }
  }

  return fetchApi({
    requestType: FETCH_UPLOAD_FLYER,
    successType: FETCH_UPLOAD_FLYER_SUCCESS,
    errorType: FETCH_UPLOAD_FLYER_ERROR,
    route: `/events/${eventId}/image_flyers`,
    options: {
      method: 'POST',
      body: JSON.stringify(requestParams)
    },
    onSuccessCallback,
    onErrorCallback,
    errorActions: [
      showHud('error', "Hmm... something's not working. Please wait a few moments and try again")
    ]
  })
}

export function fetchMeWithCommunities() {
  return fetchApi({
    requestType: FETCH_ME_COMMUNITIES,
    successType: FETCH_ME_COMMUNITIES_SUCCESS,
    errorType: FETCH_ME_COMMUNITIES_ERROR,
    route: '/graphql2',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `{
          me {
            id
            communities {
              id
              name
              my_community_member {
                role
              }
            }
          }
        }`
      })
    }
  })
}

export function activateEvent({ eventId, count, successActions = [], onErrorCallback }) {
  return fetchApi({
    requestType: ACTIVATE_EVENT,
    successType: ACTIVATE_EVENT_SUCCESS,
    errorType: ACTIVATE_EVENT_ERROR,
    payload: { eventId },
    schema: { event: Event },
    route: `/events/${eventId}/activate`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        count
      })
    },
    successActions,
    onErrorCallback
  })
}

export function sendUpdateEvent({
  eventId,
  content,
  recipient_states,
  onSuccessCallback,
  onErrorCallback
}) {
  return fetchApi({
    requestType: SEND_UPDATE_EVENT,
    successType: SEND_UPDATE_EVENT_SUCCESS,
    errorType: SEND_UPDATE_EVENT_ERROR,
    schema: {
      event_update: EventUpdate
    },
    route: `/events/${eventId}/updates`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        content,
        recipient_states
      })
    },
    onSuccessCallback,
    onErrorCallback
  })
}

export function archiveEvent({ eventId, onSuccessCallback }) {
  return fetchApi({
    requestType: ARCHIVE_EVENT,
    successType: ARCHIVE_EVENT_SUCCESS,
    errorType: ARCHIVE_EVENT_ERROR,
    route: `/graphql`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `
          mutation {
            archive_event(event_id:"${eventId}") {
              successful
              human_readable_error
              result {
                archived_at
              }
            }
          }
        `
      })
    },
    onSuccessCallback
  })
}

export function archiveTicketType(ticketId) {
  return fetchApi({
    requestType: ARCHIVE_TICKET_TYPE,
    successType: ARCHIVE_TICKET_TYPE_SUCCESS,
    errorType: ARCHIVE_TICKET_TYPE_ERROR,
    route: `/graphql2`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `
          mutation {
            archive_ticket_type(id:"${ticketId}") {
              id
            }
          }
        `
      })
    }
  })
}

export function createTicketType(eventId) {
  return fetchApi({
    requestType: CREATE_TICKET_TYPE,
    successType: CREATE_TICKET_TYPE_SUCCESS,
    errorType: CREATE_TICKET_TYPE_ERROR,
    route: `/graphql2`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `
          mutation {
            create_ticket_type(ticket_type_params: ${formatDoubleQuotes({
              container_id: eventId,
              container_type: 'event',
              label: 'child',
              price: 0
            })}) {
              id
            }
          }
        `
      })
    }
  })
}

export function parseEventCSV({ input, onSuccessCallback, onFailCallback }) {
  return fetchApi({
    requestType: PARSE_EVENT_CSV,
    successType: PARSE_EVENT_CSV_SUCCESS,
    errorType: PARSE_EVENT_CSV_ERROR,
    route: `/graphql`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `
          mutation {
            parse_event_csv(
              input: "${input}",
              last_names_first: false
              prefer_phone_numbers: true
            ) {
              human_readable_error
              successful
              result {
                invalid_csv
                valid_records {
                  email_address
                  first_name
                  last_name
                  phone_number
                }
              }
            }
          }
        `
      })
    },
    onSuccessCallback,
    onFailCallback
  })
}

export function fetchInvitationsBulk({ eventId, invitations }) {
  return fetchApi({
    requestType: FETCH_INVITATIONS_BULK,
    successType: FETCH_INVITATIONS_BULK_SUCCESS,
    errorType: FETCH_INVITATIONS_BULK_ERROR,
    route: `/events/${eventId}/invitations/bulk`,
    method: 'POST',
    body: JSON.stringify({
      invitations
    })
  })
}

export function fetchEventList(params) {
  return fetchApi({
    requestType: FETCH_EVENT_LIST,
    successType: FETCH_EVENT_LIST_SUCCESS,
    errorType: FETCH_EVENT_LIST_ERROR,
    route: `/graphql`,
    options: {
      method: 'POST',
      body: JSON.stringify({ query: eventListRequestQuery(params) })
    },
    onSuccessCallback: params.onSuccessCallback,
    onErrorCallback: params.onErrorCallback
  })
}

function eventListRequestQuery({ limit = 10, after = null, types, hasInvitations = false }) {
  const includeInvitation = `{
    me {
      events_connection (
        after: ${after ? `"${after}"` : null}
        limit: ${limit}
        direction: desc
        event_visibility_types: [${types}]
      ) {
        events {
          id
          name
          starts_at
          ends_at
          timezone
          state
          destroyed_at
          archived_at
          url
          user_id
          invitations_count
          event_type
          custom_host_name
          invitations {
            id
            first_name
            last_name
            destroyed_at
            display_name
            user {
              id
              verified
              app_user
              avatar {
                small_url_2x
                medium_url_2x
              }
              primary_contact_method {
                hash
                type
                verified
                masked_display_name
              }
              phone_number
              email_address
              first_name
              last_name
              destroyed_at
            }
          }
          user {
            avatar {
              small_url_2x
              medium_url_2x
            }
            verified
            app_user
            last_name
            first_name
          }
          flyer {
            type
            ... on EventFlier {
              url_2x
              watermarked_url
              thumbnail_url_2x
              watermarked_url_2x
            }
            ... on ImageFlyer {
              url_2x
              watermarked_url
              thumbnail_url_2x
              watermarked_url_2x
              web_client_loading_url
            }
            ... on VideoFlyer {
              watermarked_url_2x
              web_client_loading_url
              video_upload {
                video_url
                mimetype
              }
            }
          }
        }
        page_info {
          start_cursor
          has_next_page
          end_cursor
        }
      }
    }
  }`

  const excludeInvitation = `{
    me {
      events_connection (
        after: ${after ? `"${after}"` : null}
        limit: ${limit}
        direction: desc
        event_visibility_types: [${types}]
      ) {
        events {
          id
          name
          starts_at
          ends_at
          timezone
          state
          destroyed_at
          archived_at
          url
          user_id
          invitations_count
          event_type
          custom_host_name
          created_at
          invitations (only_my_invitations: true) {
            id
            destroyed_at
            rsvp_state
          }
          user {
            avatar {
              small_url_2x
              medium_url_2x
            }
            verified
            app_user
            last_name
            first_name
          }
          flyer {
            type
            ... on EventFlier {
              url_2x
              watermarked_url
              thumbnail_url_2x
              watermarked_url_2x
            }
            ... on ImageFlyer {
              url_2x
              watermarked_url
              thumbnail_url_2x
              watermarked_url_2x
              web_client_loading_url
            }
            ... on VideoFlyer {
              watermarked_url_2x
              web_client_loading_url
              video_upload {
                video_url
                mimetype
              }
            }
          }
        }
        page_info {
          start_cursor
          has_next_page
          end_cursor
        }
      }
    }
  }`

  return hasInvitations ? includeInvitation : excludeInvitation
}

// export function fetchUserWithContact({})
