import { apiResponseReceived, fetchApi } from './api'
import {
  Event,
  EventComment,
  EventUpdate,
  Invitation,
  Like,
  Link,
  Medium,
  MediumComment,
  User
} from 'src/schemas'

export const PUSHER_EVENT_RECEIVED = 'PUSHER_EVENT_RECEIVED'

export const FETCH_EVENT_BRANCH_LINKS = 'FETCH_EVENT_BRANCH_LINKS'
export const FETCH_EVENT_BRANCH_LINKS_SUCCESS = 'FETCH_EVENT_BRANCH_LINKS_SUCCESS'
export const FETCH_EVENT_BRANCH_LINKS_ERROR = 'FETCH_EVENT_BRANCH_LINKS_ERROR'

export const FETCH_EVENT = 'FETCH_EVENT'
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS'
export const FETCH_EVENT_ERROR = 'FETCH_EVENT_ERROR'

export const FETCH_EVENT_BY_ID = 'FETCH_EVENT_BY_ID'
export const FETCH_EVENT_BY_ID_SUCCESS = 'FETCH_EVENT_BY_ID_SUCCESS'
export const FETCH_EVENT_BY_ID_ERROR = 'FETCH_EVENT_BY_ID_ERROR'

export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR'

export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR'

export const FETCH_CONTACT_METHOD = 'FETCH_CONTACT_METHOD'
export const FETCH_CONTACT_METHOD_SUCCESS = 'FETCH_CONTACT_METHOD_SUCCESS'
export const FETCH_CONTACT_METHOD_ERROR = 'FETCH_CONTACT_METHOD_ERROR'

export const FETCH_EVENT_UPDATES = 'FETCH_EVENT_UPDATES'
export const FETCH_EVENT_UPDATES_SUCCESS = 'FETCH_EVENT_UPDATES_SUCCESS'
export const FETCH_EVENT_UPDATES_ERROR = 'FETCH_EVENT_UPDATES_ERROR'

export const FETCH_EVENT_COMMENTS = 'FETCH_EVENT_COMMENTS'
export const FETCH_EVENT_COMMENTS_SUCCESS = 'FETCH_EVENT_COMMENTS_SUCCESS'
export const FETCH_EVENT_COMMENTS_ERROR = 'FETCH_EVENT_COMMENTS_ERROR'

export const SET_DESTINATION_TYPE = 'SET_DESTINATION_TYPE'

export const SET_EVENT_TOKEN = 'SET_EVENT_TOKEN'
export const SET_EVENT_ID = 'SET_EVENT_ID'
export const SET_USER_ID = 'SET_USER_ID'
export const SET_ENTITIES = 'SET_ENTITIES'
export const SET_NOTIFICATION_PREFERENCE = 'SET_NOTIFICATION_PREFERENCE'

export const EVENT_SUBSCRIBE = 'EVENT_SUBSCRIBE'
export const EVENT_SUBSCRIBE_SUCCESS = 'EVENT_SUBSCRIBE_SUCCESS'
export const EVENT_SUBSCRIBE_ERROR = 'EVENT_SUBSCRIBE_ERROR'

export const EVENT_NOT_FOUND = 'EVENT_NOT_FOUND'

export const PAGE_LOADED = 'PAGE_LOADED'

export const GET_MESSAGE_COUNT = 'GET_MESSAGE_COUNT'
export const GET_MEDIA_COUNT = 'GET_MEDIA_COUNT'
export const SET_INVITATIONS_COUNT = 'SET_INVITATIONS_COUNT'
export const SET_INVITATIONS_ACCEPTED_COUNT = 'SET_INVITATIONS_ACCEPTED_COUNT'
export const SET_PROCESSED_PHOTO_COUNT = 'SET_PROCESSED_PHOTO_COUNT'
export const SET_PROCESSED_VIDEO_COUNT = 'SET_PROCESSED_VIDEO_COUNT'

export const FETCH_EVENT_BY_ME = 'FETCH_EVENT_BY_ME'
export const FETCH_EVENT_BY_ME_SUCCESS = 'FETCH_EVENT_BY_ME_SUCCESS'
export const FETCH_EVENT_BY_ME_ERROR = 'FETCH_EVENT_BY_ME_ERROR'

export function pageLoaded() {
  return {
    type: PAGE_LOADED
  }
}

export function fetchEventBranchLinks(eventToken, invitationToken = '') {
  let route = `/meta/branch/event_links/${eventToken}`
  if (invitationToken !== '') {
    route += `/${invitationToken}`
  }

  return fetchApi({
    requestType: FETCH_EVENT_BRANCH_LINKS,
    successType: FETCH_EVENT_BRANCH_LINKS_SUCCESS,
    errorType: FETCH_EVENT_BRANCH_LINKS_ERROR,
    route,
    options: {
      method: 'POST',
      body: JSON.stringify({
        branch_link_keys: [
          'floating_button',
          'email_host_message_attempt',
          'message_icon',
          'install_banner',
          'gated_chat',
          'open_in_app_modal'
        ]
      })
    }
  })
}

export function fetchEvent(eventIdOrToken, onSuccessCallback) {
  return fetchApi({
    requestType: FETCH_EVENT,
    successType: FETCH_EVENT_SUCCESS,
    errorType: FETCH_EVENT_ERROR,
    route: `/events/${eventIdOrToken}`,
    schema: {
      event: Event
    },
    autoRetry: 1,
    onSuccessCallback
  })
}

export function fetchEventById({ ids, onSuccessCallback }) {
  return fetchApi({
    requestType: FETCH_EVENT_BY_ID,
    successType: FETCH_EVENT_BY_ID_SUCCESS,
    errorType: FETCH_EVENT_BY_ID_ERROR,
    route: `/graphql`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `{
          events (ids: [${ids.map(id => `"${id}"`)}]) {
            id
            name
            flyer {
              type
              ... on EventFlier {
                thumbnail_url_2x
              }
              ... on ImageFlyer {
                thumbnail_url_2x
              }
              ... on VideoFlyer {
                thumbnail_url_2x
              }
            }
            invitations {
              id
              rsvp_state
              user {
                id
                first_name
                last_name
              }
            }
          }
        }`
      })
    },
    onSuccessCallback
  })
}

export function fetchEventByDraftAndMe({ onSuccessCallback }) {
  return fetchApi({
    requestType: FETCH_EVENT_BY_ME,
    successType: FETCH_EVENT_BY_ME_SUCCESS,
    errorType: FETCH_EVENT_BY_ME_ERROR,
    route: `/graphql`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `{
          me {
            events_connection (
              direction: asc
              event_visibility_types: [hosted_by,draft]
            ) {
              events {
                id
                name
                starts_at
                ends_at
              }
            }
          }
        }`
      })
    },
    onSuccessCallback
  })
}

export function eventSubscribe(eventId, rsvp = '', successActions = []) {
  return fetchApi({
    requestType: EVENT_SUBSCRIBE,
    successType: EVENT_SUBSCRIBE_SUCCESS,
    errorType: EVENT_SUBSCRIBE_ERROR,
    successActions,
    route: `/events/${eventId}/subscribe`,
    options: {
      method: 'POST',
      body: JSON.stringify({
        event_subscription: {
          rsvp
        }
      })
    },
    schema: {
      invitation: Invitation
    }
  })
}

export function fetchContactMethod() {
  return fetchApi({
    requestType: FETCH_CONTACT_METHOD,
    successType: FETCH_CONTACT_METHOD_SUCCESS,
    errorType: FETCH_CONTACT_METHOD_ERROR,
    route: '/user/contact_methods'
  })
}

export function fetchUser(userId) {
  return fetchApi({
    requestType: FETCH_USER,
    successType: FETCH_USER_SUCCESS,
    errorType: FETCH_USER_ERROR,
    route: `/users/${userId}`,
    schema: {
      user: User
    }
  })
}

export function fetchUpdates(eventId, page = 1, per_page = 25) {
  return fetchApi({
    requestType: FETCH_EVENT_UPDATES,
    successType: FETCH_EVENT_UPDATES_SUCCESS,
    errorType: FETCH_EVENT_UPDATES_ERROR,
    route: `/events/${eventId}/updates`,
    paginationParams: {
      entityKey: 'event_updates',
      page,
      per_page
    },
    schema: {
      event_updates: [EventUpdate]
    }
  })
}

export function fetchEventComments(eventId, page = 1, per_page = 25) {
  return fetchApi({
    requestType: FETCH_EVENT_COMMENTS,
    successType: FETCH_EVENT_COMMENTS_SUCCESS,
    errorType: FETCH_EVENT_COMMENTS_ERROR,
    route: `/events/${eventId}/comments`,
    paginationParams: {
      entityKey: 'comments',
      page,
      per_page
    },
    schema: {
      comments: [EventComment]
    }
  })
}

function mapPusherEventTypeToSchema(eventType, data) {
  switch (eventType) {
    case 'event-update-created':
    case 'event-update-updated':
    case 'event-update-destroyed':
      return { event_update: EventUpdate }
    case 'event-updated':
      return { event: Event }
    case 'medium-destroyed':
      return { medium: Medium }
    case 'photo-processed':
      return { photo: Medium }

    case 'comment-created':
    case 'comment-updated':
    case 'comment-destroyed':
      if (data.comment.commentable_type === 'Event') {
        return { comment: EventComment }
      } else if (data.comment.commentable_type === 'Medium') {
        return { comment: MediumComment }
      }

      return null
    case 'invitation-created':
    case 'invitation-updated':
    case 'invitation-destroyed':
      return { invitation: Invitation }

    case 'like-created':
    case 'like-updated':
    case 'like-destroyed':
      return { like: Like }

    case 'link-created':
    case 'link-updated':
    case 'link-destroyed':
      return { link: Link }

    default:
      return null
  }
}

export function fetchUsers({ ids, onSuccessCallback }) {
  return fetchApi({
    requestType: FETCH_USERS,
    successType: FETCH_USERS_SUCCESS,
    errorType: FETCH_USERS_ERROR,
    route: '/graphql',
    options: {
      method: 'POST',
      body: JSON.stringify({
        query: `{
          users (ids: [${ids.map(id => `"${id}"`)}]) {
            id
            app_user
            verified
            primary_contact_method {
              type
              hash
              masked_display_name
            }
          }
        }`
      })
    },
    onSuccessCallback
  })
}

export function pusherEventReceived(eventType, data) {
  return apiResponseReceived({
    type: PUSHER_EVENT_RECEIVED,
    schema: mapPusherEventTypeToSchema(eventType, data),
    payload: {
      eventType
    },
    response: data
  })
}

export function setDestinationType(destinationType) {
  return {
    type: SET_DESTINATION_TYPE,
    destinationType
  }
}

export function setEventToken(eventToken) {
  return {
    type: SET_EVENT_TOKEN,
    eventToken
  }
}

export function setEventId(eventId) {
  return {
    type: SET_EVENT_ID,
    eventId
  }
}

export function setUserId(userId) {
  return {
    type: SET_USER_ID,
    userId
  }
}

export function setNotificationPreference(invitationId, value) {
  return {
    type: SET_NOTIFICATION_PREFERENCE,
    invitationId,
    value
  }
}

export function eventNotFound() {
  return {
    type: EVENT_NOT_FOUND
  }
}

export function getMessageCount(messageCount) {
  return {
    type: GET_MESSAGE_COUNT,
    messageCount
  }
}

export function getProcessedMediaCount(mediaCount) {
  return {
    type: GET_MEDIA_COUNT,
    mediaCount
  }
}

export function setProcessedPhotoCount(photoCount) {
  return {
    type: SET_PROCESSED_PHOTO_COUNT,
    photoCount
  }
}

export function setProcessedVideoCount(videoCount) {
  return {
    type: SET_PROCESSED_VIDEO_COUNT,
    videoCount
  }
}

export function setInvitationsCount(invitationsCount) {
  return {
    type: SET_INVITATIONS_COUNT,
    invitationsCount
  }
}

export function setInvitationsAcceptedCount(invitationAcceptedCount) {
  return {
    type: SET_INVITATIONS_ACCEPTED_COUNT,
    invitationAcceptedCount
  }
}
